import * as React from 'react'
import { Formik, Form, Field, ErrorMessage, setNestedObjectValues } from 'formik';
// import logo from '../logo.png';
// import footerLogo from '../footerLogo.png'
import * as Yup from 'yup';
import { Button, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, NavItem, TabContent, TabPane } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as ContainerActions from '../store/actions/container';
import * as UiActions from '../store/actions/ui';
// import * as ProfileActions from '../store/actions/profile';
// import Amplify, { Auth } from 'aws-amplify';
// import { Link } from "react-router-dom";
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as ContainerSelectors from '../store/selectors/container';
import * as ProfileSelectors from '../store/selectors/profile';
import { FaTrashAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
// import * as UiSelectors from '../store/selectors/ui';
import {
  PageWrapper,
  Title,
  SubTitle,
  StyledLabel as Label,
  StyledInput as Input,
  StyledInlineErrorMessage,
  CodeWrapper,
  StyledButton,
  CenteredText,
  ImgFooter,
  OutlinedButton
} from "../styles/styles";
import moment from 'moment';
import { RouteConst } from '../consts/common';
import * as UiSelectors from '../store/selectors/ui';
import classnames from 'classnames';
import { TabContainer } from 'react-bootstrap';
var _ = require('lodash');

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}
const ExperimentsSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  start_date: Yup.date()
    .default(new Date())
    .required('Required'),
  end_date: Yup.date()
    .when(
      "start_date",
      (start_date: any, schema: { min: (arg0: any, arg1: string) => any; }) => start_date && schema.min(start_date, 'End date must be later than start date'))
    .required('Required'),
});
export const Home: React.FC = () => {
  const containers = useSelector(ContainerSelectors.getContainers)
  const sync_sessions = useSelector(ContainerSelectors.getSyncSessions)
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const dispatch = useDispatch();
  // const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  // const UiErrors = useSelector(UiSelectors.getErrors)
  const container = "2a7d6526-845f-460a-b66d-ac01b00da063"

  React.useEffect(() => {
    dispatch(UiActions.saveButtonAction("NO CHANGES"))
    dispatch(ContainerActions.willGetContainers());
  }, [])
  const userID = useSelector(ProfileSelectors.getProfileData).profile.sub

  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams] = React.useState<any>({})
  const toggleEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value)
    console.log('in modal edit', value)
  };
  const [modalDelete, setModalDelete] = React.useState(false);
  const [modalDeleteContainer, setModalDeleteContainer] = React.useState<any>({})
  const toggleModalDelete = (value: any) => {
    setModalDelete(!modalDelete);
    setModalDeleteContainer(value)
  }

  const [activeTab, setActiveTab] = React.useState('1');

  const toggleTabs = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const listedSyncSessions = sync_sessions.map((value: any, i: number) => {
    return (
      <tr key={i}>
        <th scope="row" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { dispatch(push(`/sync/session/${value.session}`)) }}>ID...</th>
        <td>TITOLO</td>
        <td>DESCRIZIONE</td>
        <td>START</td>
        <td>STOP</td>
      </tr>
    )
  })
  const orderedContainers = _.orderBy(containers, [(container:any)=>container.title.toUpperCase()], ["asc"]);

  const myListedContainers = orderedContainers.map((value: any, i: number) => {
    return (
      <>
      {(userID == value.owner) ?(
      <tr key={i}>
        <th scope="row" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { dispatch(push(`/detail/${value.container}`)) }}>{value.container.substring(0, 8)}...</th>
        <td>{value.title}</td>
        <td>{value.description}</td>
        <td>{moment(value.start_time).format('LL')}</td>
        <td>{moment(value.end_time).format('LL')}</td>
        <td>{(userID == value.owner) ? (<Button onClick={() => { toggleEdit(value) }}>{t('modifica')}</Button>) : (<></>)}</td>
        <td><Button onClick={() => { dispatch(ContainerActions.willCloneContainer(value.container, value.owner)) }}>{t('clona')}</Button></td>
        <td>{(userID == value.owner) ? (<FaTrashAlt color='red' onClick={() => { toggleModalDelete(value.container) }} />) : (<></>)}</td>
      </tr>):(<></>)}
      </>
    )
  })


  const listedContainers = orderedContainers.map((value: any, i: number) => {
    return (
      <>
      {(userID != value.owner) ?(
      <tr key={i}>
        <th scope="row" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { dispatch(push(`/detail/${value.container}`)) }}>{value.container.substring(0, 8)}...</th>
        <td>{value.title}</td>
        <td>{value.description}</td>
        <td>{moment(value.start_time).format('LL')}</td>
        <td>{moment(value.end_time).format('LL')}</td>
        <td><Button onClick={() => { dispatch(ContainerActions.willCloneContainer(value.container, value.owner)) }}>{t('clona')}</Button></td>
      </tr>):(<></>)}
      </>
    )
  })
  const [modal, setModal] = React.useState(false);

  const toggle = () => setModal(!modal);


  return (
    <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>

      <div className="App">
        <Header />
        <div className="App-header">
          <Container>
            {sync_sessions != [] &&
              <Row>
                <Col sm="12">
                  <PageWrapper>
                    <Title>Sessioni Sincrone</Title>
                    <Row>
                      <Col sm={12}>
                        <Table striped style={{ fontSize: "18px" }}>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>{t('titolo')}</th>
                              <th>{t('descrizione')}</th>
                              <th>{t('inizio')}</th>
                              <th>{t('fine')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listedSyncSessions}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </PageWrapper>
                </Col>
              </Row>
            }
            <Row>
              <Col sm="12">
                <PageWrapper>
                  <Title>{t('esperimenti')}</Title>
                  <Row>
                    <Button onClick={toggle} style={{ float: "left" }}>{t('aggiungi_esperimenti')}</Button>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Nav tabs>
                      <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer', color:"grey" }}
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggleTabs('1'); }}
                          >
                            {t('tab_myesperimenti')}
          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer', color:"grey"}}
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggleTabs('2'); }}
                          >
                            {t('tab_esperimenti')}
          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                          <Table striped style={{ fontSize: "18px" }}>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>{t('titolo')}</th>
                                <th>{t('descrizione')}</th>
                                <th>{t('inizio')}</th>
                                <th>{t('fine')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listedContainers}
                            </tbody>
                          </Table>
                        </TabPane>
                      </TabContent>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                        <Table striped style={{ fontSize: "18px" }}>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>{t('titolo')}</th>
                                <th>{t('descrizione')}</th>
                                <th>{t('inizio')}</th>
                                <th>{t('fine')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {myListedContainers}
                            </tbody>
                          </Table>
                        </TabPane>
                      </TabContent>

                    </Col>
                  </Row>
                </PageWrapper>
              </Col>
            </Row>
          </Container>
          <Modal isOpen={modal} toggle={toggle} >
            <ModalHeader toggle={toggle}>{t('aggiungi_esperimenti')}</ModalHeader>
            <ModalBody>
              <Formik
                initialValues={{
                  title: '',
                  description: '',
                  start_date: new Date(),
                  end_date: new Date()
                }}
                validationSchema={ExperimentsSchema}
                validateOnBlur={true}
                onSubmit={values => {
                  console.log("quote values: ", values);
                  dispatch(ContainerActions.willAddContainer(
                    values.title,
                    values.description,
                    values.start_date,
                    values.end_date,
                  ));
                  setModal(!modal)
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Container>
                    <Form name="create_quote" method="post">
                      <FormGroup row>
                        <Label sm={3}>{t('titolo')}</Label>
                        <Col sm={9}>
                          <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                          {errors.title && touched.title ?
                            <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('descrizione')}</Label>
                        <Col sm={9}>
                          <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('inizio')}</Label>
                        <Col sm={9}>
                          <Input name="start_date" type="date" placeholder={t('inizio')} tag={Field} />
                        </Col>
                        {errors.start_date && touched.start_date ?
                          <StyledInlineErrorMessage>{errors.start_date}</StyledInlineErrorMessage> : null}
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('fine')}</Label>
                        <Col sm={9}>
                          <Input name="end_date" type="date" placeholder={t('fine')} tag={Field} />
                        </Col>
                        {errors.end_date && touched.end_date ?
                          <StyledInlineErrorMessage>{errors.end_date}</StyledInlineErrorMessage> : null}
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={9}>
                          <StyledButton block color="primary" type="submit" >{t('conferma_aggiungi_esperimenti')}</StyledButton>{' '}
                        </Col>
                        <Col sm={3}>
                          <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Container>
                )}
              </Formik>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit} >
            <ModalHeader toggle={toggleEdit}>{t('modifica_gruppo_esperimenti')}</ModalHeader>
            <ModalBody>
              <Formik
                initialValues={{
                  title: modalParams.title,
                  description: modalParams.description,
                  start_date: modalParams.start_time,
                  end_date: modalParams.end_time
                }}
                validationSchema={ExperimentsSchema}
                validateOnBlur={true}
                onSubmit={values => {
                  console.log("container values: ", values);
                  dispatch(ContainerActions.willEditContainer(
                    values.title,
                    values.description,
                    values.start_date,
                    values.end_date,
                    modalParams.container,
                    modalParams.visibility
                  ));
                  setModalEdit(!modalEdit)
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Container>
                    <Form name="create_quote" method="post">
                      <FormGroup row>
                        <Label sm={3}>{t('titolo')}</Label>
                        <Col sm={9}>
                          <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                          {errors.title && touched.title ?
                            <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('descrizione')}</Label>
                        <Col sm={9}>
                          <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('inizio')}</Label>
                        <Col sm={9}>
                          <Input name="start_date" type="date" placeholder={t('inizio')} tag={Field} />
                        </Col>
                        {errors.start_date && touched.start_date ?
                          <StyledInlineErrorMessage>{errors.start_date}</StyledInlineErrorMessage> : null}
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>{t('fine')}</Label>
                        <Col sm={9}>
                          <Input name="end_date" type="date" placeholder={t('fine')} tag={Field} />
                        </Col>
                        {errors.end_date && touched.end_date ?
                          <StyledInlineErrorMessage>{errors.end_date}</StyledInlineErrorMessage> : null}
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={9}>
                          <StyledButton block color="primary" type="submit" >{t('confirm_edit_container')}</StyledButton>{' '}
                        </Col>
                        <Col sm={3}>
                          <Button color="secondary" onClick={toggleEdit}>{t('cancel')}</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Container>
                )}
              </Formik>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalDelete} toggle={toggleModalDelete} >
            <ModalHeader toggle={toggleModalDelete}>{t('elimina_gruppo_esperimenti')}</ModalHeader>
            <ModalBody>{t('confermare_elimina_gruppo_esperimenti')}</ModalBody>
            <ModalFooter>
              <Button color="danger" style={{ float: "right" }} onClick={() => { dispatch(ContainerActions.willDeleteContainer(modalDeleteContainer)); setModalDelete(!modalDelete); }}>{t('elimina')}</Button>{' '}
              <Button color="info" onClick={toggleModalDelete} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
        <Footer />
      </div>
    </BlockUi>

  );
}
