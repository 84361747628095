import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'rc-slider/assets/index.css'
import './components/Timeline/react-datetime.css'
import { render } from './App';
import { Routes } from './routes'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Timeline/react-image-lightbox.css';  
import './components/react-video-sync.css'; 
import './components/react-player.css';
import './components/resizable_modal.css';
import * as serviceWorker from './serviceWorker';
//import 'c3/c3.min.css';
// import i18n (needs to be bundled ;)) 
import i18n from './i18n';

//ReactDOM.render(<TestReactPlayer />, document.getElementById('root'));
render(Routes);

// i comandi 'console.log' vengono globalmente disabilitati in caso di
// modalità della applicazione in produzione (e non in sviluppo)
if (process.env.NODE_ENV !== "development")
    console.log = () => {};

var lng:any = (window as any).navigator['userLanguage']|| (window as any).navigator.language;
if(lng !== 'it-IT'){
    i18n.changeLanguage('en-US');
}else{
    i18n.changeLanguage(lng);
}
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();