import { all } from 'redux-saga/effects';
// import { doLoadSagas } from './sagas/auth';
import { configureAuth, doLoadSagas } from './sagas/auth';
import {doLoadContainerSagas} from './sagas/container';
// import {doLoadPaymentSagas} from './sagas/payment';

export function* rootSaga() {
  yield configureAuth();

  yield all([ doLoadSagas(),
            doLoadContainerSagas()])
  //           doLoadPaymentSagas()]);
  // yield all([yield doLoadContainerSagas()]);
}
