import React, { useState } from 'react';
import { Prompt } from 'react-router'
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import logo from '../logo.png';
// import footerLogo from '../footerLogo.png'
import * as Yup from 'yup';
import { Button, Progress, Dropdown,ButtonDropdown, CardTitle, CardHeader, CardBody, ModalHeader, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input, Navbar, NavItem, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, ModalFooter } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as ContainerActions from '../store/actions/container';
import * as UiActions from '../store/actions/ui';
// import * as AuthActions from '../store/actions/auth';
// import * as ProfileActions from '../store/actions/profile';
// import Amplify, { Auth } from 'aws-amplify';
// import { Link } from "react-router-dom";
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {TrackType, TAGS_TRACK_ID} from '../components/Timeline/Constants';
import moment from 'moment';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import * as ContainerSelectors from '../store/selectors/container';
import { RouteConst } from '../consts/common';
import classnames from 'classnames';
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from 'react-icons/fi'
import { useLocation, useParams, Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// import * as ContentSelectors from '../store/selectors/content';
import * as UiSelectors from '../store/selectors/ui';
import * as ProfileSelectors from '../store/selectors/profile';
import * as RouterSelectors from '../store/selectors/router';
import {
  PageWrapper,
  Title,
  // StyledLabel as Label,
  // StyledInput as Input,
  StyledInlineErrorMessage,
  CodeWrapper,
  StyledButton,
  CenteredText,
  ImgFooter,
  OutlinedButton
} from "../styles/styles";
import { first_item, tracks } from '../components/Timeline/FakeItems';
import { ProfileTypes } from '../store/types/profile';
import { useTranslation } from 'react-i18next';
const defTimelineStart = moment().startOf("day").add(10,"hours")
const defTimelineDuration = null;

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}
const ExperimentSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});


export const Detail: React.FC = () => {
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
let location= useLocation()
let pathID= location.pathname.slice(8, 44)
  let pathExperiment= location.pathname.slice(45)
  let saveButtonStatus= useSelector(UiSelectors.getSaveButtonStatus)

  React.useEffect(()=>{ 

    dispatch(ContainerActions.willGetContainers());
    dispatch(ContainerActions.willGetExperiments(pathID))
    dispatch(ContainerActions.willGetPublicExperiments("38a968e9-4dcb-447c-89ea-bcf22bc40661"))
  },[])

  React.useEffect(()=>{
    if(saveButtonStatus.text=="TO SAVE"){
          window.onbeforeunload=()=> ""
    }else{
      window.onbeforeunload= null
    }
    return(()=>{
        window.onbeforeunload = null
      })
  },[saveButtonStatus])

  const dispatch = useDispatch();
  const timelineStart= null; //il default lo sceglie il componente sulla base del contenuto degli item// defTimelineStart
  const timelineDuration= defTimelineDuration
  const handleItemChanged = (item: { title: any; }, event: any) => {
        console.log(`Intercettato evento su item ${item.title} di tipo ${event}`, item);
        dispatch(ContainerActions.didAddElement(item, parseInt(activeTab), experiments, event))
        dispatch(UiActions.saveButtonAction("TO SAVE"))
      }
  
  
  const experiments= useSelector(ContainerSelectors.getExperiments)
  let publicLink= useSelector(ContainerSelectors.getPublicLinks)
  if(publicLink[0]){
    publicLink=publicLink[0].publishedcontainer
  }
  console.log('public link', publicLink)
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalDelete, setModalDelete] = React.useState(false);
  const toggleModalDelete = () => setModalDelete(!modalDelete);

  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams]= React.useState<any>({})
  const toggleModalEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value)
    console.log('in modal edit',value )
  };

  const [activeTab, setActiveTab] = useState('0');
  console.log('changing tab....', activeTab)

  
  const toggleNav = (tab: React.SetStateAction<string>) => {
    if(activeTab !== tab) {setActiveTab(tab);
    dispatch(push({ pathname: RouteConst.Detail+"/"+experiments[activeTab].container+ '/' + experiments[parseInt(tab as string)].experiment}))
    }
  }

  const confirmToggleNav = (tab: React.SetStateAction<string>) =>
  {
    if (activeTab == tab) return;
    
   if (saveButtonStatus.text!="TO SAVE") toggleNav(tab);
   else
    confirmAlert({
      title: `${experiments[activeTab].title}`,
      message: `${t('uscita_senza_salvare')}`,
      buttons: [
        {
          label: `${t("tl:yes")}`,
          onClick: () => {
            toggleNav(tab);
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
    
  }

  React.useEffect(()=>{ 
console.log('in map path', pathExperiment.length, pathExperiment, experiments)
    if (pathExperiment.length > 2){
      experiments.forEach((value: any, i:number) => {
        console.log("in map 1", i, value.experiment, pathExperiment)
        if(value.experiment== pathExperiment){
                confirmToggleNav(`${i}`)
                console.log("in map", i, value.experiment)
        }
      })
    }else if(experiments.length>0){
      console.log('in map experiment   ', experiments)
      dispatch(push({ pathname: RouteConst.Detail+"/"+pathID+ '/' + experiments[0].experiment}))    }
  },[experiments])

  // controllo path esperimento per rimandare al suo tab NON FUNZIONA
// if (pathExperiment.length> 2){
//     experiments.forEach((value: any, i:number) => {
//       if(value.experiment== pathExperiment){
//               toggleNav(`${i}`)
//               console.log("in map", i, value.experiment)
//       }
//     })
//   }
let buttonStatus=<></>
  if(saveButtonStatus.text== "NO CHANGES"){
    buttonStatus= <>{t('nessuna_modifica')}</>
  }else if(saveButtonStatus.text=="SAVED"){
    buttonStatus=  <>{t('salvato')}</>
  }else if(saveButtonStatus.text=="TO SAVE"){
    buttonStatus=  <>{t('da_salvare')}</>
  }

  let ownerID = ''
  const userID = useSelector(ProfileSelectors.getProfileData).profile.sub
  if(experiments[0]){
    ownerID = experiments[0].owner
  }
  let permission = ownerID==userID

  const dropDownExperiments=  experiments.map((value: any, i:number) => {
  return (
    <NavItem key={i}>
      <NavLink
            className={classnames({ active: activeTab === `${i}` })}
            onClick={() => { confirmToggleNav(`${i}`); }}
          >
            {value.title}{'   '}
            <>
            {(activeTab == `${i}` && userID==ownerID) ? (
              <FiEdit color='blue' onClick={() => {toggleModalEdit(value)}}></FiEdit>
            ):(<></>)}
            </>
      </NavLink>
    </NavItem>
  )})

  return (
    
    <BlockUi tag="div"  loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus===true)}>
{/*
<Prompt
  when={saveButtonStatus.text=="TO SAVE" }
  message={t('uscita_senza_salvare')}
/>
*/}

    <div className="App">
      <Header />
      <div>
      <Nav tabs>
          {dropDownExperiments}
          {(userID==ownerID) ? (
        <NavItem>
          <NavLink style={{ cursor: 'pointer'}}
                onClick={toggleModal}
              >
                <BsPlusCircle color='blue' size="1.5em"/>
          </NavLink>
        </NavItem>
      ):(<></>)}
      </Nav>
    <div></div>
    </div>
    
    {(activeTab && experiments[activeTab]) ? (
      <div>
        <Row>
          <Col>
          <Card body className="text-center">
    <CardHeader tag="h4">{experiments[activeTab].title}: {experiments[activeTab].description}</CardHeader>
            {/* <CardHeader></CardHeader> */}
          </Card>
            {/* <h2>Title: {experiments[activeTab].title}</h2>
            <h4>Description: {experiments[activeTab].description}</h4> */}
          </Col>
          {(userID==ownerID) ? (
          <Col>
            <Button color="danger" onClick={toggleModalDelete} style={{float: "right"}}>{t('elimina_esperimento')}</Button>
            {/* <Button style={{float: "right"}} onClick={toggleModalEdit}>Edit Experiment title and description</Button>{' '} */}
            <Button color={saveButtonStatus.color} style={{float: "right"}} onClick={() => { dispatch(ContainerActions.willSaveExperiment(parseInt(activeTab))) }}>{buttonStatus}</Button>{' '}
            <Button color="success" style={{float: "right"}} onClick={() => { dispatch(ContainerActions.willPublishContainer(pathID)) }}>{t('pubblica')}</Button>{' '}
            {(typeof publicLink== 'string') ? (
            <Button color="light" target="_blank" style={{float: "right"}} ><Link to={`${RouteConst.Public}/${publicLink}`} target="_blank">{t('vai_a_risorsa_pubblica')}</Link></Button>
            ):(<></>)}
            </Col>
          ):(<></>)}
        </Row>
        
      <RialeTimelineViewer          activeTab={activeTab}
                                    items={experiments[activeTab].items} 
                                    tracks={experiments[activeTab].tracks} 
                                    startDateTime={timelineStart}
                                    // duration={timelineDuration}
                                    onItemChanged={handleItemChanged}
                                    title={experiments[activeTab].title}
                                    canEdit={permission}
                                    />
                                    </div>
    ):(<></>)}
<Modal isOpen={modalDelete} toggle={toggleModalDelete} >
        <ModalHeader toggle={toggleModalDelete}>{t('elimina_esperimento')}</ModalHeader>
        <ModalBody>{t('confermare_elimina_esperimento')}</ModalBody>
        <ModalFooter>
        <Button color="danger" style={{float: "right"}} onClick={() => { dispatch(ContainerActions.willDeleteExperiment(experiments[activeTab].experiment, experiments[activeTab].container)); setModalDelete(!modalDelete); }}>{t('elimina')}</Button>{' '}
        <Button color="info" onClick={toggleModalDelete} style={{float: "right"}}>{t('cancel')}</Button>{' '}
        </ModalFooter>
</Modal>




<Modal isOpen={modal} toggle={toggleModal} >
        <ModalHeader toggle={toggleModal}>{t('aggiungi_esperimento')}</ModalHeader>
        <ModalBody>
          <Formik
                  initialValues={{
                    title: '',
                    description: ''
                  }}
                  validationSchema={ExperimentSchema}
                  validateOnBlur={true}
                  onSubmit={values => {
                    console.log("exp values: ", values);
                    dispatch(ContainerActions.willAddExperiment(
                        values.title,
                        values.description,
                        pathID
                    ));
                    setModal(!modal);
                  }}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Container>
                      <Form name="create_experiment" method="post">
                        <FormGroup row>
                          <Label sm={3}>{t('titolo')}</Label>
                          <Col sm={9}>
                            <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                            {errors.title && touched.title ?
                              <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={3}>{t('descrizione')}</Label>
                          <Col sm={9}>
                            <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                            {errors.description && touched.description ?
                              <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col sm={9}>
                            <StyledButton block color="primary" type="submit" >{t('conferma_aggiunta_esperimento')}</StyledButton>{' '}
                          </Col>
                          <Col sm={3}>
                          <Button color="secondary" onClick={toggleModal}>{t('cancel')}</Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Container>
                  )}
                </Formik>
        </ModalBody>
      </Modal>


      <Modal isOpen={modalEdit} toggle={toggleModalEdit} >
        <ModalHeader toggle={toggleModalEdit}>{t('modifica_esperimento')}</ModalHeader>
        <ModalBody>
          <Formik
                  initialValues={{
                    title: modalParams.title,
                    description: modalParams.description
                  }}
                  validationSchema={ExperimentSchema}
                  validateOnBlur={true}
                  onSubmit={values => {
                    console.log("exp values: ", values);
                    dispatch(ContainerActions.didAddElement([values.title, values.description], parseInt(activeTab), experiments, "TITLE_DESCR"))
                    dispatch(UiActions.saveButtonAction("TO SAVE"))
                    setModalEdit(!modalEdit);
                  }}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Container>
                      <Form name="edit_experiment" method="post">
                        <FormGroup row>
                          <Label sm={3}>{t('titolo')}</Label>
                          <Col sm={9}>
                            <Input name="title" type="text" tag={Field} />
                            {errors.title && touched.title ?
                              <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={3}>{t('descrizione')}</Label>
                          <Col sm={9}>
                            <Input name="description" type="textarea" tag={TextAreaField} />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col sm={9}>
                            <StyledButton block color="primary" type="submit" >{t('conferma_modifica_esperimento')}</StyledButton>{' '}
                          </Col>
                          <Col sm={3}>
                          <Button color="secondary" onClick={toggleModalEdit}>{t('cancel')}</Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Container>
                  )}
                </Formik>
        </ModalBody>
      </Modal>
      <div>
      {/* <Footer /> */}
    </div>   
    </div>
       </BlockUi>
  
  );
}

export const UploadingModal = () => {
  const uploading = useSelector(UiSelectors.getUploadingState);
  const update_progress = useSelector(UiSelectors.getUpdateProgress);
  const [modal, setModalUploading] = React.useState(false);
  const uploadingToggle = () => setModalUploading(!uploading);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
console.log('uploading statusss ', uploading)
  return (
      <Modal isOpen={uploading} toggle={uploadingToggle}  >
          <ModalHeader>{t('upload_in_corso')}</ModalHeader>
          <ModalBody>
              <div>
                  <div className="text-center"><h2>{Math.trunc(update_progress)}%</h2></div>
                  <Progress bar color="info" value={Math.trunc(update_progress)} style={{ width: '70%' }} />
              </div>
          </ModalBody>
      </Modal>

  );
}
export default UploadingModal