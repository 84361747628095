import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import * as AuthApi from '../authApi';
import * as AuthType from '../types/auth';
import * as AuthActions from '../actions/auth';
// import * as ProfileType from '../types/profile';
import * as ProfileActions from '../actions/profile';
// import * as ContentActions from '../actions/content';
import * as UiActions from '../actions/ui';
// import * as UIActions from 'store/domains/ui/actions';
import { push } from 'connected-react-router';
import { RouteConst } from '../../consts/common';
import { getAuthStateErrorData, getLoginData, getLoggedData } from '../selectors/auth';
import { Auth } from 'aws-amplify';
const moment = require('moment');

export function* doLoadSagas() {
  yield all(
    [
      yield call(doCheckIfAlreadLoggedIn),

      yield takeLatest(AuthType.AuthTypes.WILL_SIGNIN, doSignIn),
      // yield takeLatest(AuthType.AuthTypes.DID_SIGNIN_FAILED, handleSignInFailed),
      yield takeLatest(AuthType.AuthTypes.WILL_FORGOT_PASSWORD, doPasswordForgot),
      yield takeLatest(AuthType.AuthTypes.WILL_FORGOT_PASSWORD_CONFIRM, doPasswordConfirmForgot),
      // yield takeLatest(AuthType.AuthTypes.WILL_CHECK_IF_LOGGEDIN, doCheckIfAlreadLoggedIn),
      yield takeLatest(AuthType.AuthTypes.WILL_SIGN_OUT, doSignOut)
      // yield call(AuthApi.doGetToken)
      // yield takeLatest(AuthType.AuthTypes.WILL_SAVE_TOKEN, configureAuthToken)
    ]
  );
}

export function* configureAuth() {
  yield call(AuthApi.doConfigureAuth);
}

export function* doSignOut() {
  try {
    yield call(AuthApi.doSignOut);
    yield put(AuthActions.didSignoutAction());
    yield put(push({ pathname: RouteConst.Login }));
  } catch (e) {
    console.log("error in signout...", e)
  }
}


export function* doPasswordForgot(forgotPasswordAction: AuthType.IWillForgotPassword) {
  console.log("in saga forgot")
  //   yield put(UIActions.willShowLoadingSpinner('Requesting Reset'));

  try {
    const result = yield call(AuthApi.doForgotPassword, forgotPasswordAction.email);
    console.log("forgot result", result)
    yield put(AuthActions.didForgotPasswordSucceededAction(result));
    yield put(push({ pathname: RouteConst.ResetPassword }));
    // yield put(UIActions.willHideLoadingSpinner());

  } catch (err) {
    console.log("error in forgot", err)
    // yield put(UiActions.didSignInFailedAction(err));
    yield put(AuthActions.didForgotPasswordSucceededAction(err));
    // yield put(UIActions.willHideLoadingSpinner());
  }
}

export function* doPasswordConfirmForgot(forgotConfirmPasswordAction: AuthType.IWillForgotPasswordConfirm) {
  //   yield put(UIActions.willShowLoadingSpinner('Confirm Reset'));
  console.log("in confirm pw saga")
  try {
    const result = yield call(
      AuthApi.doForgotPasswordConfirm,
      forgotConfirmPasswordAction.email,
      forgotConfirmPasswordAction.verificationCode,
      forgotConfirmPasswordAction.password
    );
    yield put(AuthActions.didForgotPasswordConfirmSucceededAction(result));
    yield put(push({ pathname: RouteConst.Login }));
    // yield put(UIActions.willHideLoadingSpinner());

  } catch (err) {
    // yield put(UiActions.didSignInFailedAction(err));

    yield put(AuthActions.didForgotPasswordConfirmSucceededAction(err));
    yield put(push({ pathname: RouteConst.TechnicalIssue }));
    // yield put(UIActions.willHideLoadingSpinner());
  }
}

// export function* configureAuthToken(data: AuthType.IWillSaveToken) {
//   let token = yield call(AuthApi.doSaveToken, data);
//   console.log("token", token)
// }

export function* doSignIn(signInAction: AuthType.IWillSignIn) {
  yield put(UiActions.loading(true));
  // yield put(UIActions.willShowLoadingSpinner('SignIn'));
  console.log("in saga", signInAction)
  try {

    const result = yield call(AuthApi.doSignIn, signInAction.email, signInAction.password);
    console.log('with result: ', result);
    let user = yield call(AuthApi.currentAuthenticatedUser);
    console.log("user ", user)

    yield put(AuthActions.didSignInSuceededAction(result));
    yield put(push({ pathname: RouteConst.Home }));

    // let token = yield put(AuthActions.willSaveTokenAction(user));
    // console.log("token saga sigin", token)

    // yield put(UIActions.willHideLoadingSpinner());
    let { attributes } = user
    console.log("attributes: ", attributes)
    yield put(ProfileActions.willUpdateProfile(attributes));
  } catch (err) {

    console.log('with error of type: ', err);
    yield put(UiActions.didSignInFailedAction(err));
    // yield put(UIActions.willHideLoadingSpinner());

  }
  yield put(UiActions.loading(false));

}

export function* doCheckIfAlreadLoggedIn() {
  // const logged = yield select(getLoggedData);
  // console.log("loggedddddddd", logged, pathData)
  try{
    let data = yield call(async () => Auth.currentAuthenticatedUser())
    console.log('CurrentAuthenticatedUser data 1', data)
    let { attributes } = data
    yield put(ProfileActions.willUpdateProfile(attributes));
    yield put(AuthActions.didSignInSuceededAction(data))
    yield put(AuthActions.didCheckLoginWithSuccess(true))
  
  }catch(error){
    console.log('error retrieving data from local storage')
    yield put(AuthActions.didCheckLoginWithSuccess(true))
    yield put(AuthActions.didSignoutAction())
  }


  // const path = pathData.path
  // if(!logged){
  //   const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.73c3ha89rqaos505a9c4ghhjup.LastAuthUser');
  //   const jwt = localStorage.getItem('CognitoIdentityServiceProvider.73c3ha89rqaos505a9c4ghhjup.' + `${lastAuthUser}` + '.userData')
  //   if (jwt) {
  //     const jwt_json = JSON.parse(jwt)
  //     console.log("last auth user", lastAuthUser, jwt_json.Username)
  //     if (jwt_json.Username !== lastAuthUser) {
  //       console.log("diversi")
  //     }
  //     else {
  //       yield put(AuthActions.didSignInSuceededAction({}))
  //       let data = yield call(async () => Auth.currentAuthenticatedUser())
  //       console.log('CurrentAuthenticatedUser data', data)
  //       let { attributes } = data
  //       console.log("in Login attributes: ", attributes)
  //       yield put(ProfileActions.willUpdateProfile(attributes));
  //       yield put(AuthActions.didSignInSuceededAction(data))
  //       // if(path== "/detail/:id"){
  //       //  yield put(push("/")) 
  //       // }else{
  //         // yield put(push(path)) 
  //       // }
  //     }
  //   }}
}

// export function* handleSignInFailed(signInFailedAction: AuthType.IDidSignInFailed) {

//   const errorData = yield select(getAuthStateErrorData);
//   console.log('with error ', errorData);
//   if (errorData.code === 501) {
//     const loginData = yield select(getLoginData);
//     // yield put(push({ pathname: RouteConst.RegisterSuccess, params: { email: loginData.email } }));
//     // yield put(push({ pathname: RouteConst.TechnicalIssue }));
//   }
// }

