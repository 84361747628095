import update from 'immutability-helper';

import * as ProfileTypes from '../types/profile';

const initialState: ProfileTypes.ProfileInitialState = {
    profile:{
    }

}
export const profileReducer = (state = initialState, action: ProfileTypes.ProfileActionTypes) => {
    console.log("in profile reducer:")
    switch (action.type) {
      case ProfileTypes.ProfileTypes.WILL_UPDATE_PROFILE:
          console.log("in reducer profile: ", action)
      return update(state, {  profile: {$set: action.profile}} );
      default:
        return state;
    }
}