import * as React from 'react'
import { Card, CardFooter, CardImg, CardSubtitle } from 'reactstrap'
import { push } from 'connected-react-router';
import { useDispatch } from "react-redux";
import footerLogo from '../idea.png';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const dispatch = useDispatch();

  return (
    <Card>
      <CardFooter style={{ backgroundColor: 'white', width: "35%", margin: "0 auto" }}>
        <CardImg bottom width="40%" src={footerLogo} alt="logos" />
        <br /><br />
        <CardSubtitle>Riale</CardSubtitle>
      </CardFooter>
    </Card>
  )
}