import * as React from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Button } from 'reactstrap'
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from '../store/actions/auth';
import * as ProfileSelectors from '../store/selectors/profile';
import { useTranslation } from 'react-i18next';

var logo=require('../idea.png');

export const Header: React.FC = () => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const dispatch = useDispatch();
  const profile= useSelector(ProfileSelectors.getProfileData);

  return (
    <Navbar color="faded" >

      <NavbarBrand className="mr-auto" onClick={() => { dispatch(push('/')) }}>   
              <img src={logo} height="80" style={{ display: "inline-block" , marginRight: "10px"}} />
              <h3 style={{ display: "inline-block", color: "black" }} ><strong>RIALE</strong></h3>
      </NavbarBrand>
        <Nav className="mr-2">
        <NavItem>
          <h5>{profile.profile.name}</h5>
        </NavItem>
        <NavItem>
              <Button style={{marginLeft: "10px"}} onClick={() => { dispatch(AuthActions.willSignoutAction()) }}>{t('logout')}</Button>
        </NavItem>
        </Nav>
    </Navbar>

  )
}