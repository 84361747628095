import * as React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from '../logo.png';
// import footerLogo from '../footerLogo.png'
import * as Yup from 'yup';
import { Button, FormGroup, Card, CardImg, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardFooter } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as AuthActions from '../store/actions/auth';
// import * as ProfileActions from '../store/actions/profile';
// import Amplify, { Auth } from 'aws-amplify';
// import { Link } from "react-router-dom";
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { useTranslation } from 'react-i18next';
// import * as ContentSelectors from '../store/selectors/content';
import * as UiSelectors from '../store/selectors/ui';
import {
  PageWrapper,
  Title,
  StyledLabel as Label,
  StyledInput as Input,
  StyledInlineErrorMessage,
  CodeWrapper,
  StyledButton,
  CenteredText,
  ImgFooter,
  OutlinedButton
} from "../styles/styles";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  username: Yup.string()
    .required('Required'),
});
export const Login: React.FC = () => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const dispatch = useDispatch();
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)

  return (
    
    <BlockUi tag="div"  loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus===true)}>

    <div className="App">
      <div className="App-header">
        <Container>
          <Row>
            <Col sm="12" >
              
              <PageWrapper>
                <img src={logo} alt="IDEA" width="30%"/>
                <Title>LOGIN</Title>
                  <p style={{color: "red"}}>{UiErrors}</p>
                <Formik
                  initialValues={{
                    password: '',
                    username: '',
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={values => {
                    console.log("login values: ", values);
                    dispatch(AuthActions.willSignInAction(values.username, values.password));
                  }}
                >
                  {({ errors, touched }) => (
                    <Form name="login" method="post">
                      <FormGroup>
                        <Input name="username" type="text" placeholder="Username" tag={Field} />
                        {errors.username && touched.username ?
                          <StyledInlineErrorMessage>{errors.username}</StyledInlineErrorMessage> : null}
                      </FormGroup>
                      <FormGroup>
                        <Input name="password" type="password" placeholder="Password" tag={Field} />
                        {errors.password && touched.password ? (
                          <StyledInlineErrorMessage>{errors.password}</StyledInlineErrorMessage>
                        ) : null}
                      </FormGroup>
                      <Row>
                        <Col sm={12}>
                          <StyledButton block color="primary" type="submit" >{t('login')}</StyledButton>{' '}
                        </Col>
                        {/* <Col sm={6}>
                          <StyledButton block color="secondary" onClick={() => { dispatch(push('/signup')) }}>CREATE AN ACCOUNT</StyledButton>
                        </Col> */}
                      </Row>
                    </Form>
                  )}
                </Formik>
                <Row>
                  <Col sm={12}>
                    <CenteredText>
                      <OutlinedButton outline color="link" size="sm" onClick={() => { dispatch(push('/reset')) }}>{t('forgot')}</OutlinedButton>
                    </CenteredText>
                  </Col>
                </Row>
              </PageWrapper>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>   
      </BlockUi>
  
  );
}
