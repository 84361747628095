import React, { useState } from 'react';
import {
  Button, FormGroup, Table, Modal, ModalHeader,
  ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle,
  NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon,
  InputGroupText, CardImg, Card, CardFooter, CardBody, CardHeader, CardTitle
} from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";

import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as UiSelectors from '../store/selectors/ui';

import {
  PageWrapper,
  Title,
  SubTitle,
  StyledLabel as Label,
  StyledInput as Input,
  StyledInlineErrorMessage,
  CodeWrapper,
  StyledButton,
  CenteredText,
  ImgFooter,
  OutlinedButton
} from "../styles/styles";
import * as ContainerActions from '../store/actions/container';

export const SyncSession: React.FC = () => {

  const dispatch = useDispatch();
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)

  return (
    <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>
      <div className="App">
        <Header />
        <div className="App-header">
          <Container>
            <PageWrapper>
              <Title>Sessione Sincrona</Title>
              <Row>
                <Col>
                  <Card style={{ color: 'black' }}>
                    <CardHeader>
                      <CardTitle>Camera 1</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=upleft"))}>UL</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=up"))}>UP</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=upright"))}>UR</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("rzoom=+200"))}>ZOOM +</Button></Col>
                      </Row>
                      <Row className="mt-1">
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=left"))}>LEFT</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=home"))}>HOME</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=right"))}>RIGHT</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=stop"))}>STOP</Button></Col>
                      </Row>
                      <Row className="mt-1">
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=downleft"))}>DL</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=down"))}>DOWN</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("move=downright"))}>DR</Button></Col>
                        <Col><Button block onClick={()=>dispatch(ContainerActions.willSendSyncCommand("rzoom=-200"))}>ZOOM -</Button></Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ color: 'black' }}>
                    <CardHeader>
                      <CardTitle>Camera 2</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col><Button block>UL</Button></Col>
                        <Col><Button block>UP</Button></Col>
                        <Col><Button block>UR</Button></Col>
                        <Col><Button block>ZOOM +</Button></Col>
                      </Row>
                      <Row className="mt-1">
                        <Col><Button block>LEFT</Button></Col>
                        <Col><Button block>HOME</Button></Col>
                        <Col><Button block>RIGHT</Button></Col>
                        <Col><Button block>RESET</Button></Col>
                      </Row>
                      <Row className="mt-1">
                        <Col><Button block>DL</Button></Col>
                        <Col><Button block>DOWN</Button></Col>
                        <Col><Button block>DR</Button></Col>
                        <Col><Button block>ZOOM -</Button></Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Title>Grafico REALTIME</Title>
                </Col>
              </Row>
            </PageWrapper>
          </Container>
        </div>
      </div>
      <Footer />
    </BlockUi>
  )
}