import Amplify, { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from "react-redux";
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
// import * as AuthTypes from './types/auth';
// import * as AuthActions from '../store/actions/auth';
// import * as ProfileActions from '../store/actions/profile';
import { userInfo } from 'os';
import { push } from 'connected-react-router';

let awsConfig={} as any
if (process.env.REACT_APP_STAGE === 'production') {
  awsConfig = require('../awsConfig-prod.json');
} else if(process.env.REACT_APP_STAGE === 'dev') {
  awsConfig = require('../awsConfig-dev.json');
}else if (process.env.REACT_APP_STAGE === 'demo') {
  awsConfig = require('../awsConfig-demo.json');
}else{
  awsConfig = require('../awsConfig-integration.json');
}

export const doConfigureAuth = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: awsConfig.amplifyConfigure.Auth.identityPoolId,

      // REQUIRED - Amazon Cognito Region
      region: awsConfig.amplifyConfigure.Auth.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: awsConfig.amplifyConfigure.Auth.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: awsConfig.amplifyConfigure.Auth.userPoolWebClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    }
  });

  console.log('done configuration');
};

export const currentAuthenticatedUser = async () => {
  let user = await Auth.currentAuthenticatedUser({
    bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
})
// const { attributes }=user
console.log("auth user", user)
return user
}

export const doForgotPassword = async (email: string) => {

  let result = null;
  try {
    result = await Auth.forgotPassword(email);
    return result;
  } catch (error) {
    throw error;
  }
};

export const doForgotPasswordConfirm = async (
  email: string,
  verificationCode: string,
  password: string
) => {
  let result = null;
  try {
    result = await Auth.forgotPasswordSubmit(email, verificationCode, password);
    return result;
  } catch (error) {
    throw error;
  }  
};

export const doSignOut = async () => {
try{
  const data = await Auth.signOut();
console.log("data in signout ",data)
}catch(e){
  console.log('error signout ', e);
}
};

export const doSignIn = async (email: string, password: string) => {

  const user = await Auth.signIn(email, password);
  // const user= "utente"
  console.log('with user: ', user, email, password);
  return user;
};
export const doGetToken= async () => {
    // const jwtdecode = require('jwt-decode');
//  const jwt= localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.19b4166e-49c4-47c6-b2fd-d0ce2fce0e82.userData')
//   const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.LastAuthUser');

const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.LastAuthUser');
const jwt=localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.'+`${lastAuthUser}`+'.userData')
if(jwt){
const jwt_json= JSON.parse(jwt)
console.log("last auth user", lastAuthUser, jwt_json.Username)
if(jwt_json.Username !== lastAuthUser){
  console.log("diversi")
}
}
console.log("last auth user 0")
// const dispatch = useDispatch();

// const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.LastAuthUser');
//     const jwt = localStorage.getItem('CognitoIdentityServiceProvider.25lk6d37d8dg57pj43vvojbrj3.' + `${lastAuthUser}` + '.userData')
//     if (jwt) {
//       const jwt_json = JSON.parse(jwt)
//       console.log("last auth user", lastAuthUser, jwt_json.Username)
//       if (jwt_json.Username !== lastAuthUser) {
//         console.log("diversi")
//       }
//       else {
//          yield put(AuthActions.didSignInSuceededAction({}))
//         let data= yield call(Auth.currentAuthenticatedUser())
//           console.log('CurrentAuthenticatedUser data', data)
//           let { attributes } = data
//           console.log("in Login attributes: ", attributes)
//           yield put(ProfileActions.willUpdateProfile(attributes));
//           yield put(AuthActions.didSignInSuceededAction(data))
//           yield put(push('/'))
       

//       }
//     }
  // let jwt = jwtdecode();
}
// export const doSaveToken = async (authData: any) => {
//   const token=await localStorage.setItem('token', JSON.stringify(authData));
// console.log("token api: ", token)
// return token
// };


export const updateUserAttributes= async (ethereum: string)=>{
  let user = await Auth.currentAuthenticatedUser();

  const eth= await Auth.updateUserAttributes(user, {
    'custom:ethereum_address': ethereum
  })

  return eth
}

export const doSignup = async (params: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  // privacyNotice: boolean;
  // privacyNoticeTimestamp: number;
  // fees: boolean;
  // feesTimestamp: number;
  // newsLetters: boolean;
  // newsLettersTimestamp: number;
}) => {
  try {
    const result = await Auth.signUp({
      username: params.email,
      password: params.password,
      attributes: {
        given_name: params.firstName,
        family_name: params.lastName,
        email: params.email,
        // 'custom:paytah_verified': 'false',
        // 'custom:privacy': `${params.privacyNotice}`,
        // 'custom:privacy_date': params.privacyNoticeTimestamp,
        // 'custom:fees': `${params.fees}`,
        // 'custom:fees_date': params.feesTimestamp,
        // 'custom:marketing': `${params.newsLetters}`,
        // 'custom:marketing_date': params.newsLettersTimestamp
      },
      validationData: [] // optional
    });

    console.log('with api result: ', result);
    return result;
  } catch (err) {
    console.error('with error: ', err);
    throw err;
  }
};

export const doConfirmSignUp =async(username: string, code:string)=>{

Auth.confirmSignUp(username, code, {
  forceAliasCreation: true    
}).then(data => console.log(data))
.catch(err => console.log(err));
};
