export enum ProfileTypes {
    WILL_UPDATE_PROFILE="profile/WILL_UPDATE_PROFILE"
}
export interface IWillUpdateProfile {
    readonly type: ProfileTypes.WILL_UPDATE_PROFILE;
    readonly profile: any
  }
  export interface ProfileInitialState {
    
    profile:{
        
    }
  }
  export type ProfileActionTypes =
    | IWillUpdateProfile
    
  ;