import update from 'immutability-helper';

import * as AuthTypes from '../types/auth';

const regex = /(\{.*\})/mi;

const initialState: AuthTypes.IAuthInitialState = {
  signup: {
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  },
  login: {
    pwd: '',
    email: ''
  },
  logged: false,
  authData: null,
  errData: null,
  checked: false
};
export const authReducer = (state = initialState, action: AuthTypes.IAuthActionTypes) => {
  switch (action.type) {
    // case AuthTypes.AuthTypes.ON_FULFILL_FORM:
    //   return update(state, { login: { [action.key]: { $set: action.value } } });
    case AuthTypes.AuthTypes.WILL_SIGNIN:
      return update(state, { errData: { $set: null }, login: { email: { $set: action.email } } });
    case AuthTypes.AuthTypes.DID_SIGNIN_SUCCEEDED:
      console.log("in reducer: ", action)
      return update(state, {  logged: { $set: true }, authData: { $set: action.data }} );
    case AuthTypes.AuthTypes.DID_SIGN_OUT:
      console.log("in reducer signout: ", action)
      return update(state, {  logged: { $set: false }, authData: { $set: null }} );
    case AuthTypes.AuthTypes.DID_LOGIN_CHECKED_WITH_SUCCESS:
      return update(state, { checked: {$set : action.data}})
    // case AuthTypes.AuthTypes.DID_SIGNIN_FAILED:
    //   if (action.error.code === 'UserLambdaValidationException') {
    //     const rawError = regex.exec(action.error.message);
    //     const error = JSON.parse(rawError![0]);
    //     return update(state, { errData: { $set: error } });
    //   }
      // return update(state, { errData: { $set: action.error } });
    default:
      return state;
  }
};
