import { all, call, takeLatest, put, select, take } from 'redux-saga/effects';
import { buffers, eventChannel, END } from 'redux-saga';
import * as AuthApi from '../authApi';
// import * as ContentType from '../types/content';
// import * as ContentActions from '../actions/content';
// import * as PaymentActions from '../actions/payment';
import * as ProfileType from '../types/profile';
import * as ContainerActions from '../actions/container';
import * as UiActions from '../actions/ui';
// import * as UIActions from 'store/domains/ui/actions';
import { push } from 'connected-react-router';
import { RouteConst } from '../../consts/common';
import { getAuthStateErrorData, getLoginData } from '../selectors/auth';
import * as ContainerSelectors from '../selectors/container';

import sendSyncCommand from '../../gql_operations/sendCommand'
import addEmptyContainer from '../../gql_operations/addEmptyContainer'
import addExperiment from '../../gql_operations/addExperiment'
import deleteExperiment from '../../gql_operations/deleteExperiment'
import listContainers from '../../gql_operations/listContainers'
import listExperiments from '../../gql_operations/listExperiments'
import cloneContainer from '../../gql_operations/cloneContainer'
import deleteContainer from '../../gql_operations/deleteContainer'
import publishContainer from '../../gql_operations/publishContainer'
import getPublishedContainer from '../../gql_operations/getPublishedContainer'
import listPublishedLinks from '../../gql_operations/listPublishedLinks'
import gql from 'graphql-tag';
import AWSAppSyncClient from 'aws-appsync';
import Amplify, { Auth, Storage, graphqlOperation, API } from 'aws-amplify';
import * as ProfileSelectors from '../../store/selectors/profile';
import * as ContainerType from '../types/container'
import { first_item, tracks, items } from '../../components/Timeline/FakeItems';

let awsConfig = {} as any
if (process.env.REACT_APP_STAGE === 'production') {
  awsConfig = require('../../awsConfig-prod.json');
} else if (process.env.REACT_APP_STAGE === 'dev') {
  awsConfig = require('../../awsConfig-dev.json');
} else if (process.env.REACT_APP_STAGE === 'demo') {
  awsConfig = require('../../awsConfig-demo.json');
} else {
  awsConfig = require('../../awsConfig-integration.json'); 
}
const moment = require('moment');
// const jwtdecode = require('jwt-decode');
const uuidv4 = require('uuid/v4');

export function* doLoadContainerSagas() {

  yield takeLatest(ContainerType.ContainerTypes.WILL_ADD_CONTAINER, willAddContainer)
  yield takeLatest(ContainerType.ContainerTypes.WILL_GET_CONTAINERS, willGetContainers)
  yield takeLatest(ContainerType.ContainerTypes.WILL_GET_EXPERIMENTS, willGetExperiments)
  yield takeLatest(ContainerType.ContainerTypes.WILL_SAVE_EXPERIMENT, willSaveExperiment)
  yield takeLatest(ContainerType.ContainerTypes.WILL_ADD_EXPERIMENT, willAddExperiment)
  yield takeLatest(ContainerType.ContainerTypes.WILL_DELETE_EXPERIMENT, willDeleteExperiment)
  yield takeLatest(ContainerType.ContainerTypes.WILL_CLONE_CONTAINER, willCloneContainer)
  yield takeLatest(ContainerType.ContainerTypes.WILL_EDIT_CONTAINER, willEditContainer)
  yield takeLatest(ContainerType.ContainerTypes.WILL_DELETE_CONTAINER, willDeleteContainer)
  yield takeLatest(ContainerType.ContainerTypes.WILL_UPLOAD_FILE, willUploadFile)
  yield takeLatest(ContainerType.ContainerTypes.WILL_GET_PUBLIC_CONTAINER, willGetPublicExperiments)
  yield takeLatest(ContainerType.ContainerTypes.WILL_PUBLISH_CONTAINER, willPublishContainer)
  yield takeLatest(ContainerType.ContainerTypes.WILL_SEND_SYNC_COMMAND, willSendSyncCommand)
  //   yield takeLatest(ContentType.ContentTypes.WILL_GET_QUOTE, doGetQuote);
  //   yield call(AuthApi.doGetToken)

}


export function* willAddContainer(container: ContainerType.IWillAddContainer) {
  yield put(UiActions.loading(true));
  console.log("container form datas saga: ", container)
  console.log("data in moment: ", moment().format(container.start_date))
  // container: "2a7d6526-845f-460a-b66d-ac01b00da063"
  // experiment: "269073f6-bac7-492a-9af7-90918aba7431"
  const items1 = JSON.stringify(items)
  const tracks1 = JSON.stringify(tracks)
  let mutationContainerResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(addEmptyContainer), variables: {
          meta: "meta",
          visibility: "Public",
          title: container.title,
          description: container.description,
          start_time: container.start_date,
          end_time: container.end_date
        }
      });
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }
  })
  console.log('result add empty cont: ', mutationContainerResult.data.addEmptyContainer.container)
  const containerID = mutationContainerResult.data.addEmptyContainer.container
  let mutationAddExperiment = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(addExperiment), variables: {
          meta: "meta",
          title: "Experiment 1",
          description: "First experiment",
          container: containerID,
          model: "test",
          tags: ["tags", "test"],
          tracks: tracks1,
          items: items1
        }
      });

      console.log("response on add exp", response)
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }
  })
  console.log("add experiment result", mutationAddExperiment)

  yield put(ContainerActions.willGetContainers());
  yield put(ContainerActions.willGetExperiments(containerID));
  yield put(UiActions.loading(false));
}

export function* willSendSyncCommand(action: any) {

  let mutationSendSyncCommand = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(sendSyncCommand), variables: {
          command: action.command,
        }
      });
      console.log("response on send command", response)
      return response
    } catch (e) {
      console.log('Amplify clone saga error', e)
    }
  })
}


export function* willGetContainers() {
  yield put(UiActions.loading(true));
  const container1 = "2a7d6526-845f-460a-b66d-ac01b00da063"
  // experiment: "269073f6-bac7-492a-9af7-90918aba7431"
  let queryContainerResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.query({
        query: gql(listContainers), variables: {}
      });

      console.log("response on confirm", response)
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }

  })
  console.log("containers ", queryContainerResult)


  if (queryContainerResult) {
    yield put(ContainerActions.didGetContainers(queryContainerResult.data.listPublicContainers.containers));
    if (queryContainerResult.data.getSyncSessions) {
      yield put(ContainerActions.didGetSyncSessions(queryContainerResult.data.getSyncSessions));
    }
  }

  yield put(UiActions.loading(false));
}

export function* willGetExperiments(data: ContainerType.IWillGetExperiments) {
  yield put(UiActions.loading(true));
  console.log("data1", data.container)
  const container1 = "2a7d6526-845f-460a-b66d-ac01b00da063"
  // experiment: "269073f6-bac7-492a-9af7-90918aba7431"
  let queryExperimentsResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.query({
        query: gql(listExperiments), variables: {
          container: data.container
        }
      });

      let responsePublic = await client.query({
        query: gql(listPublishedLinks), variables: {
          container: data.container
        }
      });
      const response1 = { response, responsePublic }
      console.log("response on confirm", response1, responsePublic)
      return response1
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }

  })
  console.log("get experiments ", queryExperimentsResult)

  if (queryExperimentsResult.response.data.listExperiments.experiments.length > 0) {
    yield put(ContainerActions.didGetExperiments(queryExperimentsResult));
    yield put(push(`/detail/${data.container}`))
  } else {
    yield put(ContainerActions.didGetExperiments(queryExperimentsResult));
    yield put(push({ pathname: RouteConst.Detail }));
  }

  yield put(UiActions.loading(false));

}

export function* willSaveExperiment(data: ContainerType.WillSaveExperiment) {
  yield put(UiActions.loading(true));
  const experimentIndex = data.experimentIndex
  const experiments = yield select(ContainerSelectors.getExperiments);
  const experiment = experiments[experimentIndex]
  const items = JSON.stringify(experiment.items)
  const tracks2 = JSON.stringify(experiment.tracks)
  console.log("tracks in saving exper", tracks2, items, experiment.tracks, experiment.items)
  let mutationSaveExperiment = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(addExperiment), variables: {
          meta: experiment.meta,
          title: experiment.title,
          description: experiment.description,
          experiment: experiment.experiment,
          container: experiment.container,
          model: "test",
          tags: experiment.tags,
          tracks: tracks2,
          items: items
        }
      });
      console.log("response on save exp", response)
      return response
    } catch (e) {
      console.log('Amplify save saga error', e)
    }
  })
  console.log("save experiment result", mutationSaveExperiment)
  yield put(UiActions.saveButtonAction("SAVED"));
  yield put(UiActions.loading(false));
}


export function* willAddExperiment(data: ContainerType.IWillAddExperiment) {
  yield put(UiActions.loading(true));
  const items1 = JSON.stringify(items)
  const tracks1 = JSON.stringify(tracks)
  let mutationSaveExperiment = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(addExperiment), variables: {
          meta: 'meta',
          title: data.title,
          description: data.description,
          container: data.container,
          model: "test",
          tags: ["tags", "test"],
          tracks: tracks1,
          items: []
        }
      });
      console.log("response on add exp", response)
      return response
    } catch (e) {
      console.log('Amplify save saga error', e)
    }
  })
  console.log("add experiment result", mutationSaveExperiment)
  yield put(ContainerActions.willGetExperiments(data.container))
  yield put(UiActions.loading(false));
}

export function* willDeleteExperiment(data: ContainerType.IWillDeleteExperiment) {
  yield put(UiActions.loading(true));
  let mutationDeleteExperiment = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(deleteExperiment), variables: {
          experiment: data.experiment
        }
      });
      console.log("response on delete exp", response)
      return response
    } catch (e) {
      console.log('Amplify save saga error', e)
    }
  })
  console.log("delete experiment result", mutationDeleteExperiment)
  yield put(ContainerActions.willGetExperiments(data.container))
  yield put(UiActions.loading(false));
}


export function* willCloneContainer(data: ContainerType.IWillCloneContainer) {
  yield put(UiActions.loading(true));
  const owner = data.owner
  const containerID = data.container
  console.log("in clone", owner, containerID)
  let mutationCloneContainer = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(cloneContainer), variables: {
          container: containerID,
          owner: owner
        }
      });
      console.log("response on clone container", response)
      return response
    } catch (e) {
      console.log('Amplify clone saga error', e)
    }
  })
  console.log("clone container result", mutationCloneContainer)
  yield put(ContainerActions.willGetContainers())
  yield put(UiActions.loading(false));
}


export function* willEditContainer(container: ContainerType.IWillEditContainer) {
  yield put(UiActions.loading(true));
  let mutationEditContainerResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(addEmptyContainer), variables: {
          meta: "meta",
          container: container.container,
          visibility: container.visibility,
          title: container.title,
          description: container.description,
          start_time: container.start_date,
          end_time: container.end_date
        }
      });
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }
  })
  console.log('result edit cont: ', mutationEditContainerResult.data.addEmptyContainer.container)
  yield put(ContainerActions.willGetContainers());
  yield put(UiActions.loading(false));
}

export function* willDeleteContainer(data: ContainerType.IWillDeleteContainer) {
  yield put(UiActions.loading(true));
  let mutationDeleteContainer = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(deleteContainer), variables: {
          container: data.container
        }
      });
      console.log("response on delete exp", response)
      return response
    } catch (e) {
      console.log('Amplify save saga error', e)
    }
  })
  console.log("delete experiment result", mutationDeleteContainer)
  yield put(ContainerActions.willGetContainers());
  yield put(UiActions.loading(false));
}


export function* willUploadFile(data: ContainerType.IWillUploadFile) {
  yield put(UiActions.loading(true));
  yield put(UiActions.startUpload());
  const fileName = uuidv4() + '/' + data.url.name
  let url = data.url
  // url['name']=uuidv4()+'/'+data.url.name

  Object.defineProperty(url, 'name', {
    value: uuidv4() + '/' + data.url.name,
    writable: true
  });
  console.log("in saga start upload", fileName, url)
  let channel = yield call(async () => {
    try {
      return eventChannel(emitter => {
        Storage.configure({
          AWSS3: {
            bucket: awsConfig.amplifyConfigure.Storage.AWSS3.bucket,//Your bucket ARN;
            region: awsConfig.amplifyConfigure.Storage.AWSS3.region,//Specify the region your bucket was created in;
          }
        });

        // console.log("upload", awsConfig.amplifyConfigure.Storage.AWSS3.bucket)
        //       console.log(`upload  ${awsConfig} localstorage `,localStorage.getItem('aws.cognito.identity-id.'+awsConfig.amplifyConfigure.Auth.identityPoolId))
        // let channel = await Storage.put(`${data.url.name}`, data.url, {level: 'private'},);
        Storage.put(`${data.url.name}`, url, {
          progressCallback: function (progress: { loaded: number; total: number; }) {
            console.log(progress.loaded, progress.total);
            emitter({ progress: progress.total > 0 ? progress.loaded / progress.total : -1 })
          }
        }).then((result) => {
          emitter({ result });
          emitter(END)
        }).catch(e => {
          emitter({ err: new Error('Upload failed') });
          emitter(END);
        })
        return () => {
          //what to do here

        }
      }, buffers.sliding(2))

      // console.log(`upload result 1 : ${data.url.name} , ${JSON.stringify(channel)}`)
      // const url= 'https://740820033840-idea-riale-backend-dev-contents-bucket.s3-eu-west-1.amazonaws.com/'+'private/'+localStorage.getItem('aws.cognito.identity-id.'+awsConfig.amplifyConfigure.Auth.identityPoolId)?.replace(/:/,'%3A')+'/'+data.url.name.replace(/ /g,'+')
      // return url
    }
    catch (e) {
      console.log('Amplify upload saga error', e)
    }
  })
  yield put(UiActions.loading(false));
  console.log("upload result: ", channel)
  while (true) {
    const { progress, err, result } = yield take(channel);
    if (err) {
      console.log('UPLOAD_ERROR')
      // yield put(IdeaActions.setAppError(IdeaTypes.AppError.UPLOAD_ERROR, prev, {}));

      return;
    }
    if (result) {
      yield put(UiActions.uploadSuccess())
      console.log('result upload channel', channel, result)
      return channel;
    }
    yield put(UiActions.uploadUpdateProgress(progress * 100));

  }
}

export function* willGetPublicExperiments(data: ContainerType.IWillGetPublicExperiments) {
  yield put(UiActions.loading(true));
  console.log("container", data)
  let queryPublicExperimentsResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.public.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: "API_KEY",
          apiKey: awsConfig.appSyncClient.public.apiKey
        },
      });
      console.log("CLIENT:", client)
      let response = await client.query({
        query: gql(getPublishedContainer), variables: {
          container: data.container
        }
      });
      console.log("response on confirm", response)
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }

  })
  console.log("get public experiments ", queryPublicExperimentsResult)

  // if(queryPublicExperimentsResult.data.listExperiments.experiments.length > 0){
  //   yield put(ContainerActions.didGetPublicContainer(queryPublicExperimentsResult.data.listExperiments.experiments));
  //   yield put(push(`/detail/${data.container}`))
  // }else{
  //   yield put(ContainerActions.didGetExperiments(queryPublicExperimentsResult.data.listExperiments.experiments));
  // yield put(push({ pathname: RouteConst.Detail}));
  // }
  yield put(ContainerActions.didGetPublicContainer(queryPublicExperimentsResult.data.getPublishedContainer));

  yield put(UiActions.loading(false));

}



export function* willPublishContainer(data: ContainerType.IWillPublishContainer) {
  yield put(UiActions.loading(true));

  let publishContainerResult = yield call(async () => {
    try {
      const client = new AWSAppSyncClient({
        url: awsConfig.appSyncClient.url,
        region: awsConfig.appSyncClient.region,
        disableOffline: awsConfig.appSyncClient.disableOffline,
        auth: {
          type: awsConfig.appSyncClient.auth.type,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      console.log("CLIENT:", client)
      let response = await client.mutate({
        mutation: gql(publishContainer), variables: {
          container: data.container
        }
      });

      console.log("response on confirm", response)
      return response
    } catch (e) {
      console.log('Amplify upload saga error', e)
    }

  })
  console.log("container pubblicato: ", publishContainerResult)
  // yield put(ContainerActions.didGetPublicContainer(publishContainerResult.data.publishContainer.publishedcontainer));
  yield put(ContainerActions.willGetExperiments(data.container))

  yield put(UiActions.loading(false));
}