import * as React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route } from 'react-router-dom';
// import { Login } from './Login'
import * as AuthSelectors from '../store/selectors/auth'
import * as AuthActions from '../store/actions/auth';

export const LoginRequiredRoute = (props: any) => {
  const dispatch= useDispatch()
const logged= useSelector(AuthSelectors.getLoggedData);
dispatch(AuthActions.WillCheckIfAlreadLoggedIn(props.path));
console.log("logged? ",logged, props)
  // const logged =true
  const {children, ...rest} = props

  return (
    <Route {...rest} render={(props: { location: any; }) => ( 
      logged ? (
        children
      ) : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />
        )
    )} />

  )
}
