// Video Player Api Doc:    https://video-react.js.org/components/player/
// React Timeline API Doc:  https://github.com/namespace-ee/react-calendar-timeline#api
// React Timeline examples: https://github.com/namespace-ee/react-calendar-timeline/tree/master/examples
// Moment: https://stackoverflow.com/questions/18623783/get-the-time-difference-between-two-datetimes/18624295
// Reactstrap: https://reactstrap.github.io/components/alerts/
// Conditional props: https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
// States and Props: https://stackoverflow.com/questions/40063468/react-component-initialize-state-from-props
// Player Sync: https://stackoverflow.com/questions/57808628/is-there-a-way-to-keep-two-or-more-video-elements-synchronized-using-react
// React Portals:https://www.deadcoderising.com/react-16-render-a-child-component-into-another-dom-node-using-portals/
//               https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/

import React, { Component } from 'react';
import ModalItemEditor, {ItemPreview} from "./Timeline/ItemEditor";
import RialeModalPanel, {Portal} from "./Timeline/RialeModalPanel";
import TimelinePlayer from "./Timeline/TimelineToolbar";
import RialeIotViewer from "./Timeline/RialeIotViewer";
import RialeVideoSyncPlayer from './Timeline/RialeVideoSyncPlayer'; //Backup
import { IconContext } from "react-icons";
import moment from 'moment';
import Timeline, {CursorMarker, CustomMarker } from 'react-calendar-timeline'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import ReactTooltip from "react-tooltip";

import KeyboardEventHandler from 'react-keyboard-event-handler';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {TAGS_TRACK_ID, TrackType, ItemEvent, DefItemIcons} from './Timeline/Constants';
import {getTimelineItems} from './Timeline/Utils';
import { CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import {get_fake_iot_item} from './Timeline/FakeItems';
import RialeItemsNavigator from './Timeline/RialeItemNavigator';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
//https://reactjsexample.com/tag/sidebar/
import { LinearProgress } from '@material-ui/core';

// ESEMPIO SIDEBAR
//https://github.com/zesik/react-splitter-layout/blob/master/example/javascripts/components/TogglableSidebarLayout.jsx

//ZIndex
//https://coder-coder.com/z-index-isnt-working/

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "track",
  itemTimeStartKey: "start_time",
  itemTimeEndKey: "end_time",
  groupLabelKey: "title"
};

    // INIZIALIZZAZIONE DELLA FINESTRA TEMPORALE DELLA TIMELINE
    // finestra temporale di visualizzazione (default: 1 giorno)
    var TIMELINE_WINDOW_DURATION = moment.duration(40,'minutes');
    const MIN_ZOOM = 10*60*1000; // 10 minuti in ms
    const MAX_ZOOM = 365.24 * 86400 * 1000; // a anno in ms
    
    const ADD_IOT_FAKE_ITEM = false; // disabilitato in quanto upload funzionante

class RialeTimelineViewerNT extends Component {
  
  constructor(props) {
    super(props);
    this.rialeTimelineRef = React.createRef();
     
    console.log("COSTRUTTORE RIALE TIMELINE VIEWER");
    if (this.props.duration!=null)
      {
        console.log(`Trovata prop di duration diverso da null pari a ${this.props.duration}`);
        TIMELINE_WINDOW_DURATION = this.props.duration;
      }
      else
      {
        console.log(`Trovata prop di duration NULL: Lascio il default a ${TIMELINE_WINDOW_DURATION }`);
      }
      

    // la giornata odierna di default -> codice aggiunto anche in componentDidMount
    const visibleTimeStart = this.props.startDateTime== null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;
    // la fine della finestra è dettata dall'estensione della finestra temporale
    const visibleTimeEnd = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION);
    // la posizione del cursore la imposto a metà della finestra
    const currentPositionDate = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION/4);
     // -----------------------------

     let tl_items = getTimelineItems(props.items, props.canEdit,true);
     console.log(tl_items);
     if (ADD_IOT_FAKE_ITEM)
     {
       console.log("tentativo creazione item di IOT di test...");
       const item = get_fake_iot_item()
       console.log(`Item IOT: ${item.start_time}`);
      tl_items.push(item);
     }
       

    this.state = {
      // i gruppi sono le varie tracce (di tipo TAG,VIDEO, DOCUMENT)
      groups: props.tracks,
      // contiene tutti gli item (i tag sono item a tutti gli effetti)
      items: tl_items,
      // contiene la url corrente del JSON coi campioni IOT,
      iot_items: [],
      // posizione temporale corrente
      currentPositionDate,
      // posizione temporale iniziale della finestra
      visibleTimeStart,
      // posizione temporale finale della finestra
      visibleTimeEnd,
      timelineWindowDuration: TIMELINE_WINDOW_DURATION,
      source: null, // "http://www.w3schools.com/html/mov_bbb.mp4",
      videoUpdateCode:0, // usato per avvisare il sync player di un cambio di posizione manuale
      videoItems: [],
      videoReadyState:0,
      buffering: false, // indica se il video è in fase di caricamento (buffering o player.state.isWaiting)
      videoActivated: false,
      selectedItem:null,
      addModalOpen: false,
      intervalId : -1,
      timeline_t0 : null,
      timeline_e0 : null,
      collidingItems : [],
      itemDraggingTime: null, 
      draggingItem: null, // usato per tracciare lo spostamento dei tag
      timelineIsPlaying : false, // indica se la timeline è in stato di play
      autoScroll: false, // se posto a true, forza l'autoScroll programmato (disabilita il manuale)
      showTimeCursor: false,
      videoPanelVisible:true,
      attachmentsPanelVisible:true,
      itemsNavigatorPanelVisible:false,
      secondaryPaneWitdhPerc: 0, // 35 // [TODO] variabile da eliminare...non più usata...
      modalPanelsReady: false,
      areVideoLoading: false,
      timelineEditingEnabled: true
    };
    //console.log("state", this.state)
  }

  getInitialStartTime = (items) => {
    if (items==null || items.length<1)
    return moment().startOf("day");

    let firstItem = items[0];
    let firstDateTime = moment(items[0].start_time) 
    for (let i=1; i<items.length; i++)
    {if (moment(items[i].start_time).isBefore(firstDateTime))
      firstDateTime = moment(items[i].start_time) 
      firstItem = items[i]
    }
    console.log(`Data iniziale della timeline:${firstDateTime}`);
    console.log(`Primo item: ${firstItem.id}`);
     return firstDateTime;
  }


  onItemDeleted = (item) =>
  {
   
    if (item==null)
    return;

    console.log(`Richiesta rimozione Item: ${item.title} con id ${item.id}`);
    
    const new_items = this.state.items.slice();
    for (let index in new_items)
      {
        if (new_items[index].id==item.id)
        {
          console.log(`Elimino l'item ${item.title} con id ${item.id}`);
          new_items.splice(index,1);
          console.log("Notifico al parent la eliminazione dell'item");
          this.props.onItemChanged(item, ItemEvent.DELETED);
        } 
      }
      this.setState({items: new_items});
      // aggiorno le eventuali collisioni con gli item
      this.updateItemCollisions();
  }

 onItemEdited = (item) =>
 {
    const new_items = this.state.items.slice();
    console.log(`Cerco l'item con id ${item.id}`);
   const oldItem = this.getItemById(item.id);

   console.log("RICHIESTA MODIFICA ITEM");
   if (oldItem==null)
     {
      console.log(`Creo un nuovo item ${item.title}`);
      new_items.push(item);
      console.log("Notifico al parent la CREAZIONE di un nuovo item");
      this.props.onItemChanged(item, ItemEvent.CREATED);
     }
    else{
      for (let index in new_items)
      {
        if (new_items[index].id==item.id)
        {
          console.log(`Aggiorno l'item ${item.title}`);
          new_items[index] = item;
          console.log(`Notifico al parent la MODIFICA di un item esistente: ${new_items!=this.state.items}`);
          this.props.onItemChanged(item, ItemEvent.CHANGED);
        } 
      }
    }
    console.log("Aggiorno la lista degli item da onItemEdited");
    this.setState({items: new_items, selectedItem:null}, () =>{this.updateItemCollisions(); 
                                                                this.updateVideoCollisions()});
                                                                
 }

  toggle = () => {
    console.log(`Invocato toogle!`);
    this.setState( 
     prevState => ({addModalOpen : !prevState.addModalOpen })                   
          );
    }

    toggleTimelineEditing = () => {
      console.log(`Invocato onToggleTimelineEditing!`);
      this.setState( 
       prevState => ({timelineEditingEnabled : !prevState.timelineEditingEnabled,
      
        items: getTimelineItems(this.props.items, this.props.canEdit, !prevState.timelineEditingEnabled)})                   
            );
      }
 
  getItemById = (itemId) =>
  { 
    const {items} = this.state;
    console.log("Dentro getItemById");
    let itemIndex;
    for (itemIndex in items)
    {
      const item = items[itemIndex]
      console.log(`Analisi item: ${item}`)
      if (item.id==itemId)
      return item;
    }
    return null;
  }

  getItemsByGroupId = (groupId) =>
  {
    const {items} = this.state;
    let groupItems = [];

    for (let itemIndex in items)
    {
      const item = items[itemIndex]
     
      if (item.track==groupId)
        groupItems.push(item);
    }
    return groupItems;
  }


  getGroupById = (groupId) =>
  { 
    const {groups} = this.state;
    //console.log(`Ho trovato {groups.length} gruppi`);
    
    for (let groupIndex in groups)
    {
      const track = groups[groupIndex]
      //console.log(`Individuato gruppo con id: ${track.id} contro ${groupId}`);
      if (track.id==groupId)
      return track;
    }
    console.warn(`Non ho trovato alcun gruppo con id:${groupId}`);
    return null;
  }


  componentDidMount() {
    console.log("Richiamato componentDidMount");
    console.log(`Main panel Width: ${this.rialeTimelineRef.current.offsetWidth}`)
    // la giornata odierna di default
    const visibleTimeStart = this.props.startDateTime== null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;
    // la fine della finestra è dettata dall'estensione della finestra temporale
    const visibleTimeEnd = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION);
    // la posizione del cursore la imposto a metà della finestra
    const currentPositionDate = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION/4);

    this.setState({"modalPanelsReady": true,visibleTimeStart, visibleTimeEnd,currentPositionDate
  }, () =>{this.updateItemCollisions()});
  }

  componentDidUpdate(prevProps, prevState) {
    // questo mi viene stampato
    //console.log(`Richiamato componentDidUpdate con #items prima: ${prevProps.items.length}`);
    //console.log(`Richiamato componentDidUpdate con #items dopo: ${this.props.items.length}`);
    //console.log(`--> MOMENT: prima ${prevProps.startDateTime} Poi ${this.props.startDateTime}`);
    
   
    if (prevProps.startDateTime!==this.props.startDateTime || 
      prevProps.duration!==this.props.duration)
    {
      //const pEq = _.isEqual(prevProps, this.props);
      //console.log(`Proprietà modificate? ${!pEq}`);
      console.log(`modificato: prima ${prevProps.startDateTime} Poi ${this.props.startDateTime}`);
      this.setState({timelineWindowDuration: this.props.duration}, () =>
          {
            const newPositionDate =  moment(this.props.startDateTime).add(this.state.timelineWindowDuration/2);
            this.updateCurrentDatetimePosition(newPositionDate)
          })
    }


    if (prevProps.items!=this.props.items || prevProps.tracks!=this.props.tracks)
      {
        console.log("Aggiorno tracce ed items");
        this.setState(
          {groups: this.props.tracks, items: getTimelineItems(this.props.items, this.props.canEdit, this.state.timelineEditingEnabled) },
          
          () => {
            if (prevProps.activeTab!==this.props.activeTab)
            {
              console.log(`Cambio TAB: Selezione altro esperimento:startDateTime:${this.props.startDateTime}`);
              // la giornata odierna di default
                const newPositionDate = this.props.startDateTime== null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;
                
                console.log(`Cambio TAB: ${newPositionDate}`);
                this.handlePlayerDatetimeChanged(newPositionDate);
            }
              else{
                      console.log("In componentDidUpdate aggiorno collisioni")
                      // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
                      this.updateItemCollisions();
                      // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
                      this.updateVideoCollisions(null, true);
                    }
          });
      
     
      
      }
  }

  
  handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    /*
    console.log(
      "TIME CHANGE",
      moment(visibleTimeStart, "x").format(),
      moment(visibleTimeEnd, "x").format()
    );
   */
    this.setState({
    visibleTimeStart,
    visibleTimeEnd
    });
    
    // aggiorno il canvas
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  }

  verticalLineClassNamesForTime = (timeStart, timeEnd) => {
    const currentTimeStart = moment(timeStart);
    const currentTimeEnd = moment(timeEnd);

    let classes = [];

    // check for public holidays
        classes.push("holiday");
        return classes;
    }

    /** 
     * Riposiziona il marker della timeline nella posizione indicata
     * centrandolo rispetto allo schermo e scrollando adeguatamente la timeline
     * secondo la finestra aggiornata coi nuovi (visibleTimeStart,visibleTimeEnd)
     * @param currentPositionDate nuova data e orario della timeline
     */
    updateCurrentDatetimePosition = (newPositionDate) =>
    {
    
      const {timelineWindowDuration, currentPositionDate, secondaryPaneWitdhPerc,  visibleTimeStart} = this.state;
      //const newVisibleTimeStart = moment(visibleTimeStart).add(newPositionDate).subtract(currentPositionDate);
      //console.log(`AGGIORNO la posizione del marker con finestra temporale di ${timelineWindowDuration.humanize()}`);
      //console.log(`CP:${currentPositionDate} -> ${newPositionDate}`);
      // [TODO] AGGIORNARE OPPORTUNAMENTE LA FINESTRA TEMPORALE...
      const newVisibleTimeStart = moment(newPositionDate).subtract(timelineWindowDuration/2);//(200/(1+secondaryPaneWitdhPerc)));
      const newVisibleTimeEnd = moment(newVisibleTimeStart).add(timelineWindowDuration);
      //console.log(`Nuova finestra: Da ${newVisibleTimeStart} a ${newVisibleTimeEnd} Pos:${newPositionDate}`);
      //console.log("Abilito temporaneamente AUTO SCROLL");
      // abilito l'autoScroll temporaneamente per centrare la finestra nella data selezionata
      this.setState( {currentPositionDate: newPositionDate,
                     autoScroll: true,
                     visibleTimeStart : newVisibleTimeStart,
                     visibleTimeEnd : newVisibleTimeEnd
                     }, () =>{
                            // disabilito l'autoScroll
                            //console.log("Disabilito AUTO SCROLL"); 
                            this.setState({autoScroll:false, });

                            // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
                            this.updateItemCollisions(newPositionDate);
                            
                              });
    }

    handleZoomChanged = (newDuration) =>
    {
      const {currentPositionDate} = this.state;
      if (newDuration==null)
        {
          this.setState({timelineWindowDuration: TIMELINE_WINDOW_DURATION} , () => {this.updateCurrentDatetimePosition(currentPositionDate)});
         return;
        }

      const currentDurationInMs = newDuration.asMilliseconds();
      console.log(`Min: ${MIN_ZOOM} Max: ${MAX_ZOOM} Durata proposta: ${currentDurationInMs}`);
      if (currentDurationInMs>=MIN_ZOOM && currentDurationInMs<=MAX_ZOOM)
      {
        this.setState({timelineWindowDuration: newDuration} , () => {this.updateCurrentDatetimePosition(currentPositionDate)});
      
      }
      else if (currentDurationInMs<MIN_ZOOM)
      {
        this.setState({timelineWindowDuration: moment.duration(MIN_ZOOM)} , () => {this.updateCurrentDatetimePosition(currentPositionDate)});
      }
      else if (currentDurationInMs>MAX_ZOOM)
      {
        this.setState({timelineWindowDuration: moment.duration(MAX_ZOOM)} , () => {this.updateCurrentDatetimePosition(currentPositionDate)});
      }
    }

    /** 
     * Calcola la nuova posizione del marker della timeline e 
     * richiama il metodo di aggiornamento della nuova posizione
     */
    timelineProgress =  (velFactor) => {
         // t_i = e_0 + (now() - t_0)*velFactor
         const {timeline_t0, timeline_e0} = this.state;
         if (velFactor==null)
         velFactor = 1
         const newPositionDate =  moment(moment(timeline_e0) + (moment() - moment(timeline_t0))* velFactor);
        // aggiorna la posizione del marker sulla base dell'istante corrente
         this.updateCurrentDatetimePosition(newPositionDate); 
         //this.setState( {currentPositionDate: newPositionDate});

         // verifica la collisione con risorse video
         this.updateVideoCollisions(newPositionDate,true, false); // true,false PRIMA [VERIFICARE]
         const {videoActivated} = this.state;

         // in caso di collisione col video arresto il timer del Player della Timeline
         // in modo che subentri l'handler del player video per leggere il tempo corrente
         // e aggiornare il marker
         if (videoActivated===true)
         {
          clearInterval(this.state.intervalId);
          console.log("Collisione video trovata. Ho disabilitato il player della timeline");
         }
       }
      
  
  /**
  * Aggiorna le collisioni tra la data corrente e gli eventuali video
  * Imposta opportunamente lo stato videoActivate a null (in assenza di collisioni) oppure - per ora -  al primo item di tipo video trovato...da generalizzare a N video...)
  * @param positionDate data corrente da verificare 
  * @param updateVideoCode informa il RialeVideoSyncPlayer che è stato eseguito un cambio di tempo proveniente dal parent
  * @param canvasClick specifica se la chiamata arriva da una interazione dell'utente (true) o dal progress della timeline interna
  */
  updateVideoCollisions = (positionDate, updateVideoCode, canvasClick) =>
  {
    
    const {items, currentPositionDate} = this.state;
    
    // se non specificato, si intende che la richiesta di aggiornatmento della posizione della timeline
    // provenga dal Player della time line e NON dal SyncPlayer
    if (updateVideoCode==null)
    updateVideoCode = true;
    if (canvasClick==null)
    canvasClick = true;

    //console.log(`Verifico collisione video che per ora risulta-->: ${videoActivated}`);

    let videoItems = [];

    const positionDateCmp = (positionDate==null) ? currentPositionDate : positionDate;
    for (let index in items)
    {
      //const track = this.getGroupById(items[index].track)
      //console.log(`Analisi del gruppo:${track.title}`);
      //console.log(`Orario di confronto:${moment(positionDateCmp)} su ${items.length} items`);
      
      if (items[index].type===TrackType.VIDEO)
      {
        //console.log(`Trovato gruppo VIDEO:${track.title}`);
         
        //console.log(`ANALIZZO Item Start: ${items[index].start_time} Item End: ${items[index].end_time}`);
        if (moment(positionDateCmp).isBetween(items[index].start_time,items[index].end_time, null, '[)'))
        {
          videoItems.push(items[index]);
          // aggiorno la sorgente video e la colloco nella posizione corretta
         
          //console.log(`Ho trovato il video : ${items[index]}`);
        }
      }
    }

    // verifico se i videoItems sono cambiati e 
    // nel caso aggiorno lo stato
    this.checkVideoItems(videoItems, updateVideoCode, positionDateCmp, canvasClick);
  }

  checkVideoItems = (items, updateVideoCode, currentPositionDate,canvasClick) =>
  {
    //console.log("CHECK VIDEO COLLISIONS");
    const {videoItems} = this.state;

    if (items.length<1 && canvasClick) {
      console.log("Timeline gestita dal setInterval:Aggiorno il valore della progress timeline!");
      this.setState({timeline_t0 : moment(),
                    timeline_e0: moment(currentPositionDate)
                    }       
      )
    }

    let modified = false;
    if (items.length!=videoItems.length) 
      {
        console.log(`TIMELINE: Numero videoItems non corrispondenti: ${items.length} contro ${videoItems.length}`);
        console.log(`TIMELINE: Posizione stato interno Timeline  ${moment(this.state.currentPositionDate).format("HH:mm:ss")}
         CONTRO ${moment(currentPositionDate).format("HH:mm:ss")}`);
        modified = true;
        if (items.length>0)
        {
          console.log("Ho un videoItem, arresto il progressInterval del play della timeline");
          clearInterval(this.state.intervalId);
        }
       

      }
      else
      {
          for (let i=0;i<items.length;i++)
          {
            if (`${items[i].id}`.localeCompare(`${videoItems[i].id}`)!=0 ||
            `${items[i].title}`.localeCompare(`${videoItems[i].title}`)!=0 ||
            `${items[i].description}`.localeCompare(`${videoItems[i].description}`)!=0 ||
            !moment(items[i].start_time).isSame(videoItems[i].start_time) ||
            !moment(items[i].start_offset).isSame(videoItems[i].start_offset) ||
            !moment(items[i].end_offset).isSame(videoItems[i].end_offset) 
            ) 
            {
              console.log(`ID dei videoItems non corrispondenti: *${items[i].id}* contro *${videoItems[i].id}*`);
              modified = true;
              break;
            }
          }

        }

         if (modified)
         {
          console.log(`Aggiorno i video items che sembrano cambiati in orario ${moment(currentPositionDate).format("HH:mm:ss")}, Ne ho ${items.length} updateVideoCode ?:${updateVideoCode}`);
          this.setState(prevState =>({
            videoUpdateCode : updateVideoCode ? (prevState.videoUpdateCode + 1) % 10 : prevState.videoUpdateCode,
            videoItems:items, videoActivated: items.length>0
          }));
         }
         // caso in cui gli item NON siano stati modificati
         else if (updateVideoCode)
         {
           
          
           //console.log(`UpdateVideoCode: Valore corrente: ${this.state.videoUpdateCode}`);
          this.setState(prevState =>({
            videoUpdateCode : (prevState.videoUpdateCode + 1) % 10
          }));
         }
  }
  
 
  /** 
   * Verifica le intersezioni tra la posizione corrente (o quella specificata)
   * della timeline e i vari items e aggiorna la list
   * @param positionDate posizione della timeline di cui verificare le intersezioni
  */
  updateItemCollisions = (positionDate) =>
  {
    //console.log("richiamata updateItemCollisions");
    const {items, currentPositionDate} = this.state;
    let collidingItems = [];
    let iotItems =  [];

    const positionDateCmp = (positionDate==null) ? currentPositionDate : positionDate;
    for (let index in items)
    {
      if (moment(positionDateCmp).isBetween(items[index].start_time,items[index].end_time, null, '[)'))
      {
        collidingItems.push(items[index]);
        const url = items[index].source;
        if (url!=null && items[index].type==TrackType.IOT)  
        {
          iotItems.push(items[index]);
        }
      }
    }
    //console.log(`Individuati ${iotItems.length} iot items`);
    this.setState({collidingItems, iot_items:iotItems});
  }
  
  /** 
   * Avvia il Play della timeline usando la funzione timelineProgress
  */
  playTimeline = () =>
    {
      const {videoActivated} = this.state;
      // se il video risulta attivo l'avanzamento della 
      // timeline è delegato al RialeVideoSuncPlayer
      if (videoActivated)
      {
        console.log(`Timeline in play mediante SyncPlayer`);
        this.setState({timelineIsPlaying:true})
        return;
      }
      console.log("Richiamata playTimeline()");
      const {currentPositionDate, intervalId} = this.state;
      // se esiste già in esecuzione un timer della timeline lo arresta
      if (intervalId!=null)
      clearInterval(intervalId);

      let newIntervalId = setInterval(this.timelineProgress,10);
      this.setState({
      intervalId: newIntervalId,
      timeline_t0 : moment(),
      timeline_e0: moment(currentPositionDate),
      timelineIsPlaying : true
      });
    }


  /** 
   * Mette in pausa la timeline rimuovendo il Timer (clearInterval)
   */
  pauseTimeline = () =>
  {
    console.log("Richiamata pauseTimeline()");
    clearInterval(this.state.intervalId);
    this.setState({timelineIsPlaying : false});
  }

  toggleTimeCursor = () =>
  {
    console.log(`Aggiornamento timeCursor: ${this.state.showTimeCursor}`);
    this.setState( 
      prevState => ({showTimeCursor : ! prevState.showTimeCursor})                   
           );
  }

  toggleAttachments = () =>
  {
    console.log(`Aggiornamento vista allegati::: ${this.state}`);
    this.setState( 
      prevState => ({attachmentsPanelVisible : ! prevState.attachmentsPanelVisible})                   
           );
  }

  toggleItemsNavigator = () =>
  {
    console.log(`Aggiornamento vista Navigator::: ${this.state}`);
    this.setState( 
      prevState => ({itemsNavigatorPanelVisible : ! prevState.itemsNavigatorPanelVisible})                   
           );
  }


  renderIotItems()
  { 
    const {iot_items, currentPositionDate, timelineIsPlaying,visibleTimeStart,
      visibleTimeEnd, timelineWindowDuration, posixPanelRect}
           = this.state;
           //console.log(`Individuati su renderIotItems ${iot_items.length} items`);
     return iot_items.map((iot_item, index) =>(
      <div style={{backgroundColor:"white"}} key={index}>
          <RialeIotViewer 
              className="iotViewer"
              iotItem = {iot_item}
              bounds={"window"}
              currentPositionDate = {currentPositionDate}
              timelineWindowDuration = {timelineWindowDuration}
              visibleTimeStart = {visibleTimeStart}
              visibleTimeEnd = {visibleTimeEnd}
              timelineIsPlaying = {timelineIsPlaying}
              posixPanelRect = {posixPanelRect}
              onContainerRectChanged={this.onPosixPanelRectChanged}
          />
         </div>
         ))
  }


  onPosixPanelRectChanged= (state) =>
  {
    console.log(`IotViewer Rect Changed x:${state.x} y:${state.y} w:${state.width} h:${state.height}`);
    const posixPanelRect = {x: state.x,y: state.y, width: state.width,height : state.height};
    console.log("Aggiorno Posix rect panel");
    this.setState({posixPanelRect});
  }

  /**
   * esegue il render degli item correntemente attivi
   * in funzione della posizione corrente del marker della timeline
   */
  renderItemsPreview()
  {
      //console.log("Dentro renderItemsPreview");
      const {collidingItems} = this.state;
      return collidingItems.map( (item,index) => (
        item.type!=TrackType.VIDEO &&  <ItemPreview item={item} index={index} key={item.id}/>
        ));
  }

  /**
   * Esegue il rendering delle barre verticali (marker) associate 
   * agli item di tipo TAG
   */
  renderMarkersTag()
   {
     const tags = this.getItemsByGroupId(TAGS_TRACK_ID);
     const {itemDraggingTime, draggingItem} = this.state;
     const markerDate =  itemDraggingTime;
     // (draggingItem!=null && draggingItem.id==tag.id ?  itemDraggingTime :parseInt(tag.start_time.valueOf()));
      
     return tags.map( (tag,index) => (
       
      <CustomMarker date={(draggingItem!=null && draggingItem.id==tag.id ?  itemDraggingTime :parseInt(tag.start_time.valueOf()))} 
                    key={tag.id}>      
          {({ styles, date }) => {
            const customStyles = {
              ...styles,
              backgroundColor: 'blue',
              top:0,
              width: '3px',
              //zIndex: 100
            }
            return <div style={customStyles}>
              </div>
          }
        }
      </CustomMarker>
      ));
   }

   itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const bgColor = item.bgColor== null? 'blue' : item.bgColor;
    const selectedBgColor = item.selectedBgColor==null ? 'rgb(200,100,0)' : item.selectedBgColor;
    const color = (item.color== null ||itemContext.selected) ? 'white' : item.color;
    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : selectedBgColor) : bgColor;
    //const itemTooltip = itemContext.selected ? (itemContext.dragging ? moment(item.start_time).format("HH:mm:ss") : "") : "";
    //console.log(`ITEM TOOLTIP:${itemTooltip}`);
    //const itemTooltip = (itemContext.dragging ? moment(item.start_time).format("HH:mm:ss") : "???");
    return (
      <div {...getItemProps({
        style: {
          fontSize:'18px',
          textAlign:'left',
          paddingLeft:'10px',
          textOverflow: "ellipsis",
          overflow: "hidden",
          backgroundColor,
          color
        }})}> 
       
       <div title={item.title} style={{ marginBottom: 10, marginRight: 5, width: 20, height: 20, float: 'left', padding: 0, flex: 1}}>
            <IconContext.Provider value={{ color: color, className: "global-class-name" }}>
              {DefItemIcons(item)}
            </IconContext.Provider> 
      </div>

<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.title}</div>
        </div>)
        
     
   }

   groupRenderer = ({ group }) => {
    return (
      <div className="custom-group">
        <span className="title"><b>{group.title}</b></span>
        <p className="tip">{group.tip}</p>
      </div>
    )
  }


  /** 
   * Callback richiamata dal RialeSyncVideoPlayer 
  */
 handleProgress = (currentPositionDate, itemIndex) =>
 {
  console.log(`Arrivato Progress time da RVS (playing:${this.state.timelineIsPlaying}) (Item ${itemIndex}): ${currentPositionDate}`);

   this.setState({currentPositionDate, areVideoLoading:false}, ()=>{
        this.updateVideoCollisions(currentPositionDate,false);
        this.updateItemCollisions();
        if (this.state.timelineIsPlaying){
          this.updateCurrentDatetimePosition(currentPositionDate);
        }

   });

   //console.log(`Progress time: ${currentPositionDate}`);
   if (this.state.timelineIsPlaying)
   {
     this.updateCurrentDatetimePosition(currentPositionDate);
     //console.log("Verifico nuove collisioni video da handleProgress...");
     //n.b: in questo caso l'aggiornamento proviene dal SyncPlayer
     // [TODO percheè null e non currentPositionDate?!]
     this.updateVideoCollisions(currentPositionDate, false); // prima era null e non si capisce perchè...
   }
   else
     this.setState({currentPositionDate}, () => {this.updateItemCollisions(); 
                                                // this.updateVideoCollisions();
                                               });
 }

handleEnded = () =>
{
  console.log(`Video Terminati in orario ${this.state.currentPositionDate}`);
  const {timelineIsPlaying} = this.state;
  this.setState({videoActivated: false, areVideoLoading:false}, () => 
            { 
              if (timelineIsPlaying)
              { console.log("La timeline risulta in stato di play quindi la avvio");
                this.playTimeline();
              }
              else {
                console.log("La timeline risulta in pausa e quindi non la riavvio");
              }
            });
 
}

handleStartVideoLoading = () =>
{
  console.log(`Richiamato handleStartVideoLoading`);
  this.setState({areVideoLoading:true});
}

getTimeCursorWidth = () =>
{
  return (this.state.showTimeCursor ? '2px' : '0px');
}

getTimeCursorContent = (date, customStyles) =>
{
  //return <div style={{...customStyles}}>CIAO</div>
  if (!this.state.showTimeCursor)
  return null;
  else
  return <div style={{...customStyles}}><span style={{color:'red', backgroundColor:'yellow', 'margin-left': '5px'}}><b>&nbsp;{moment(date).format("HH:mm:ss.SSS")}&nbsp;</b></span></div>                    
}

handleSecondaryPaneSizeChange = (secondaryPaneWitdhPerc) => {
  console.log(`NEW PANE SIZE: ${secondaryPaneWitdhPerc} %`);
  const {currentPositionDate} = this.state;
  //this.setState({secondaryPaneWitdhPerc}); //, () => this.updateCurrentDatetimePosition(currentPositionDate));
}

  renderAttachmentsAndVideoArea()
  {
    const playerDivStyle = {display:'block', width:'100%'}
    // i18n translation
    const {t} = this.props;
    const {videoItems, currentPositionDate,videoUpdateCode, timelineIsPlaying} = this.state;


     return (
      
      (containerWidth, containerHeight, visible) =>(
       
      <SplitterLayout  percentage secondaryInitialSize={50} primaryMinSize={2} secondaryMinSize={2}>


    <div  className="attachmentsHandle" style={playerDivStyle}>
        <CardTitle className="handle" style={{color:"green",  
        marginTop: '10px', 
        cursor:"move"}}><h4><b>{t("tl:Sezione Allegati")}</b></h4></CardTitle>
          
          <div  style={{'overflowY': 'auto', 'height': containerHeight-80}}>
              { this.renderItemsPreview() }
          </div>
      </div>
      
       <div className="videoHandle" style={playerDivStyle}> 
       <CardTitle className="handle" style={{color:"green", 
       marginTop: '10px', 
       cursor:"move"}}><h4><b>{t("tl:Sezione Video")}</b></h4></CardTitle>
       <div  style={{'overflowY': 'auto', 'height': containerHeight-80}}>
         <RialeVideoSyncPlayer items={videoItems} 
                            maxPlayers = {4}
                            currentPositionDate = {currentPositionDate} 
                            videoUpdateCode = {videoUpdateCode}
                            timelinePlaying={timelineIsPlaying} 
                            onProgress={this.handleProgress} 
                            onEnded={this.handleEnded} 
                            onStartLoading={this.handleStartVideoLoading}

                            />
            </div> 
            </div>
           
      
        
      <ReactTooltip place="right"/>    
      </SplitterLayout> )) 
  }

  confirmItemDelete = (item) =>
  {
    const selectedItem = item != null ? item : this.state.selectedItem;
    if (selectedItem==null) return;

    const {t} = this.props;
 
    confirmAlert({
      title: `${t("tl:item_delete")}`,
      message: `${t("tl:item_delete_confirm")} ${selectedItem.title} ?`,
      buttons: [
        {
          label: `${t("tl:yes")}`,
          onClick: () => {this.onItemDeleted(selectedItem)}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
    
  }
  render() {

    const {items, groups, selectedItem, selectedGroup, addModalOpen, videoItems, 
      visibleTimeStart,visibleTimeEnd, timelineIsPlaying,
      currentPositionDate, autoScroll, videoUpdateCode,draggingItem,itemDraggingTime,
      timelineWindowDuration, areVideoLoading, timelineEditingEnabled} = this.state;
    
    
    
    const itemGroup = (selectedItem!=null) ? this.getGroupById(selectedItem.track) : selectedGroup;
    
    var timelineCommonProps= {
      scrollRef:el => (this.scrollRef = el),
      groups:groups,
      items:items,
      keys:keys,
      lineHeight:50,
      itemHeightRatio : 0.65,
      minZoom: MIN_ZOOM, // 10 minuti in ms
      maxZoom:1 * MAX_ZOOM, // 1 anno in ms
      //sidebarWidth=220,
      //traditionalZoom : true,
      itemTouchSendsClick: false,
      sidebarWidth:180,
      fullUpdate: true,
        onItemSelect:this.handleItemSelect,
        onItemDeselect:this.handleItemDeselect,
        onItemDrag:this.handleItemDrag,
        onItemClick:this.handleItemClick,
        onItemDoubleClick :this.handleItemDoubleClick,
        onCanvasDoubleClick:this.handleCanvasDoubleClick,
        onCanvasClick:this.handleCanvasClick,
        onZoom:this.handleZoom,
        onBoundsChange: (canvasTimeStart, canvasTimeEnd) => {console.log("BOUNDS CHANGED!")},
        timeSteps:{
          second: 1,
          minute: 1,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
         resizeDetector:containerResizeDetector 
        },
        dragSnap:1*1000, // risoluzione di 1 secondo
        onItemMove:this.handleItemMove,
        onItemResize:this.handleItemResize,
        verticalLineClassNamesForTime:this.verticalLineClassNamesForTime,
        itemRenderer:this.itemRenderer,
        groupRenderer:this.groupRenderer,
        style:{zIndex:10},
        onTimeChange:this.handleTimeChange
    }

    // zoom e scroll manuale nel caso il player della timeline non sia in esecuzione e non stia
    // forzando lo scroll automatico
    if (!timelineIsPlaying &&  !autoScroll)
    {
      // zoom manuale 
      timelineCommonProps.defaultTimeStart=moment(visibleTimeStart);
      timelineCommonProps.defaultTimeEnd=moment(visibleTimeEnd);
    }
    // se la timeline è in Play oppure sono in modalità di autoScroll forzato, lo scrolling è gestito dalla TimelineToolbar
    else
    {
      // zoom programmato
      timelineCommonProps.visibleTimeStart=parseInt(visibleTimeStart.valueOf());
      timelineCommonProps.visibleTimeEnd=parseInt(visibleTimeEnd.valueOf());
    }

    

    return (
      
    <div id="portalareadiv" className="portalarea" ref={this.rialeTimelineRef}>
    
    <KeyboardEventHandler
          handleKeys={['del']}
          onKeyEvent={(key, e) => 
          {
            //console.log(`KEY EVENT:do something upon keydown event of ${key}`);
            this.confirmItemDelete();
            //this.onItemDeleted(this.state.selectedItem);
          }}
          />
       
    
      <div className="my-pane" style={{borderStyle: "solid",  borderColor: "grey",
                                    'overflowY': 'auto', 'height': '100%'}}>

      


      { addModalOpen && (
        
           
          <ModalItemEditor buttonLabel="Aggiungi" 
                          isOpen={addModalOpen} 
                          toggle={this.toggle}
                          onItemEdited={this.onItemEdited}
                          onItemDeleted={this.confirmItemDelete}
                          visibleTimeStart={parseInt(visibleTimeStart.valueOf())}
                          visibleTimeEnd={parseInt(visibleTimeEnd.valueOf())}
                          item = {selectedItem}
                          track={itemGroup}
                          start={currentPositionDate.toDate()}
                          />
          )}

            <div id="itemsNavigator"></div> 
            <div id="portal"></div> 

      

            <Timeline {...timelineCommonProps} >
              
               {this.renderMarkersTag()} 
              

              <CustomMarker date={parseInt(currentPositionDate.valueOf())}>
              
                {({ styles, date }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: 'red',
                    width: '2px',
                    zIndex: 100
                  }
                  return <div style={customStyles}>
                    </div>
                }
               
              }
               </CustomMarker>

               <CursorMarker>
                        {({ styles, date }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: 'black',
                            width:  this.getTimeCursorWidth(),
                            zIndex: 100,
                            top:0
                          }
                          //return <div style={{...customStyles}}><span style={{color:'red', backgroundColor:'yellow', 'margin-left': '5px'}}><b>&nbsp;{moment(date).format("HH:mm:ss.SSS")}&nbsp;</b></span></div>
                          return this.getTimeCursorContent(date, customStyles)
                        }
                      }
                  </CursorMarker>
                
                
            </Timeline>
           
               { timelineIsPlaying && areVideoLoading && <LinearProgress/> }
            

            <TimelinePlayer 
            currentPositionDate={currentPositionDate} 
            items={items}
            canEdit={this.props.canEdit}
            zoomDuration={timelineWindowDuration}
            onZoomChanged={this.handleZoomChanged}
            selectedItem={selectedItem}
            draggingItem={draggingItem}
            itemDraggingTime={itemDraggingTime}
            isPlaying={timelineIsPlaying} 
            isAttachmentsPanelVisible={this.state.attachmentsPanelVisible}
            isTimeCursorVisible={this.state.showTimeCursor}
            isEditingEnabled={timelineEditingEnabled}
            isItemsNavigatorPanelVisible={this.state.itemsNavigatorPanelVisible}
            onPlay={this.playTimeline} 
            onPause={this.pauseTimeline} 
            onToggleTimeCursor = {this.toggleTimeCursor}
            onToggleTimelineEditing = {this.toggleTimelineEditing}
            onToggleAttachments = {this.toggleAttachments}
            onToggleItemsNavigator = {this.toggleItemsNavigator}
            onDateChanged={this.handlePlayerDatetimeChanged} />  
 
        { this.renderIotItems()}
       
          </div>

          {this.state.modalPanelsReady &&
           <Portal target="portal">
            <RialeModalPanel visible={this.state.attachmentsPanelVisible}
                            title={moment(currentPositionDate).toString()}
                            bounds={"window"}
                            initialWidth={400}
                            initialHeight={this.rialeTimelineRef.current.offsetHeight}
                            initialX={this.rialeTimelineRef.current.offsetWidth-400}
                            initialY={0}
                            lockAspectRatio = {false}
                            onContainerRectChanged={(state)=> console.log("Attachments Rect Changed")}
                            >
                {this.renderAttachmentsAndVideoArea()}
           </RialeModalPanel>
           </Portal>
           
           }
 {this.state.modalPanelsReady &&
        <Portal target="itemsNavigator">
            <RialeModalPanel visible={this.state.itemsNavigatorPanelVisible}
                            title={moment(currentPositionDate).toString()}
                            bounds={"window"}
                            initialWidth={500}
                            initialHeight={this.rialeTimelineRef.current.offsetHeight}
                            initialX={0}
                            initialY={0}
                            onContainerRectChanged={(state)=> console.log("Navigator Rect Changed")}
                            >
                              {
                                (containerWidth, containerHeight, visible) =>{ 
                                  return (
                                  <RialeItemsNavigator height={containerHeight} 
                                  onItemSelected={this.handlePlayerDatetimeChanged}
                                  onItemEditRequest={this.handleItemDoubleClick}
                                  onItemCreateRequest={this.createItemFromNavigator}
                                  onClosePanel={this.toggleItemsNavigator}
                                  currentPositionDate={currentPositionDate}
                                  title={this.props.title} 
                                  visible={visible}
                                  canEdit = {this.props.canEdit && this.state.timelineEditingEnabled}
                                  tracks={groups} items={items}/>) 
                                  
                                }
                              }
                
           </RialeModalPanel>
           </Portal>
          }
         
           </div>
    )

  } // end of renderer
  

  handleItemDrag = (itemDragObject) =>
  {

    // n.b questo metodo non dovrebbe essere mai invocato nel caso di canEdit = false
    if (!this.props.canEdit || !this.state.timelineEditingEnabled)
    {
      console.log("Utente non abilitato allo spostamento degli item");
      return;
    }

    if (itemDragObject.eventType=="move")
    {
       console.log(`Sto spostando l'item ${itemDragObject.itemId} in time: ${itemDragObject.time}`);
       this.setState({itemDraggingTime: itemDragObject.time,
                        draggingItem: this.getItemById(itemDragObject.itemId) });
    }
    else
    {
      this.setState({itemDraggingTime: null, draggingItem:null});
    }
  }
  

  handleItemMove = (itemId, dragTime, newGroupOrder) => {

    console.log(`Richiamato handleItemMove dell'item ${itemId} sul nuovo gruppo ${newGroupOrder}`);
    const { items, groups } = this.state;

    const track = groups[newGroupOrder];
    console.log(`Id del gruppo di destinazione: ${track.id}`)
    console.log(`Id dell'item da analizzare: ${itemId}`)

    if (!this.props.canEdit || !this.state.timelineEditingEnabled)
    {
      console.log("Utente NON abilitato allo spostamento degli item");
      return;
    }

    if (this.getItemById(itemId).type!==track.type)
    {
      console.log(`Impossibile spostare un item di tipo ${this.getItemById(itemId).type} su una traccia di tipo ${track.type}`);
      return;
    }

      const new_items = items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
              start_time: moment(dragTime),
              end_time: moment(dragTime + item.end_time- item.start_time),
              track: track.id
            })
          : item
      );

      console.log(new_items);
      this.setState({items: new_items}, () => { console.log(`handleItemMove:Notifico al parent la MODIFICA di un item esistente`);
                                              this.props.onItemChanged(this.getItemById(itemId), ItemEvent.CHANGED);});
    

    console.log("Moved", itemId, dragTime, newGroupOrder);
    //aggiorno l'insieme delle collisioni degli items
    this.updateItemCollisions(this.state.currentPositionDate);
    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(this.state.currentPositionDate,true);
  };

  handleItemResize = (itemId, time, edge) => {
    const { items } = this.state;
    console.log(`Richiamato Resize on ${edge}`);
    // non consento il resize sulla sinistra

    if (!this.props.canEdit || !this.state.timelineEditingEnabled)
    {
      console.log("Utente NON abilitato al ridimensionamento degli item");
      return;
    }

    if (edge!=="right") return;

    this.setState({
      items: items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
              start_time: edge === "left" ? moment(time) : moment(item.start_time),
              end_time: edge === "left" ? moment(item.end_time) : moment(time),
              duration:  moment(time).diff(moment(item.start_time), 'seconds', true)  
            })
          : item
      )
    }, () => {this.setState({selectedItem: this.getItemById(itemId)});
                console.log(`handleItemResize:Notifico al parent la MODIFICA di un item esistente`);
                this.props.onItemChanged(this.getItemById(itemId), ItemEvent.CHANGED)
              });

    console.log("Resized", itemId, moment(time).format(), edge);
  };

  handleZoom = (timelineContext) =>
    {
     // var x = new moment()
      //var y = new moment()
      //var duration = moment.duration(x.diff(y))
      // returns duration object with the duration between x and y
      const newWindowDuration = moment.duration(moment(timelineContext.visibleTimeEnd).diff(timelineContext.visibleTimeStart));
      //console.log(`HandleZoom con newWindowDuration:${newWindowDuration.humanize()}`);
      this.setState({
                    timelineWindowDuration: newWindowDuration,
                    visibleTimeStart : timelineContext.visibleTimeStart,
                    visibleTimeEnd : timelineContext.visibleTimeEnd
                    });
    }
  
  handlePlayerDatetimeChanged = (newPositionDate) =>
  {  
    if (!moment(newPositionDate).isValid())
    {
      console.log(`Invalid Date: ${newPositionDate}`);
      return;
    }     
    
    this.updateCurrentDatetimePosition(newPositionDate);
    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(newPositionDate, true);
  }
  
  handleCanvasClick = (groupId, time, e) =>
  {
    this.setState({
      currentPositionDate : moment(time),
      selectedGroup: this.getGroupById(groupId),
    }, () => {
            // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
          this.updateItemCollisions(time);
          
          // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
          this.updateVideoCollisions(time, true);
      
    });
  }


  handleItemDoubleClick = (itemId, e, time) => 
  {
    if (this.state.timelineIsPlaying && this.props.canEdit)
    {this.pauseTimeline();}

    console.log(`handleItemDoubleClick  CanEdit:${this.props.canEdit} Editing Enabled: ${this.state.timelineEditingEnabled}`);

    const selectedItem = this.getItemById(itemId);

    const newPositionDate = moment(time).isValid() ? moment(time) : this.state.currentPositionDate;

    this.setState({
      currentPositionDate : newPositionDate,
      addModalOpen : this.props.canEdit && this.state.timelineEditingEnabled,
      selectedItem,
      selectedGroup: this.getGroupById(selectedItem.track),
      modalUpload:false
    })

     // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
     this.updateVideoCollisions(time,true);

  }

  createItemFromNavigator = (groupId, time, e) =>
  {
    this.setState({selectedItem:null}, () =>{
      this.handleCanvasDoubleClick(groupId, time, e)
    })
  }

  // Gestisce il click sui punti della timeline dove NON ci sono item
  handleCanvasDoubleClick = (groupId, time, e) =>
  {
    

   if (this.state.timelineIsPlaying && this.props.canEdit && this.state.timelineEditingEnabled)
    {
      this.pauseTimeline();
    }

     // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
     this.updateVideoCollisions(time,true);

    //console.log(`canvas Click sul tempo: ${moment(time)} Evento:${e}`);
    if (groupId == null)
    {
      alert("Doppio click con zona non associata a un gruppo")
      return;
    }
   
    const selectedGroup = this.getGroupById(groupId)
    console.log(`Canvas double click su gruppo ${selectedGroup.id}: ${selectedGroup.title} CanEdit:${this.props.canEdit} Editing enabled: ${this.state.timelineEditingEnabled}`);
    //alert(`Istante selezionato: ${ moment(time)}`);
      
    this.setState({
      currentPositionDate : moment(time),
      addModalOpen : this.props.canEdit && this.state.timelineEditingEnabled,
      selectedGroup
    })
  }

  handleItemDeselect = (e) =>
  {
    this.setState({itemDraggingTime: null, 
      draggingItem: null, selectedItem: null});
  }


   

  //Gestisce la selezione di un item (richiamato solo se l'item non era già selezionato)
  handleItemSelect = (itemId, e, time) =>
  { 
  
    const item = this.getItemById(itemId);
  
    const track = this.getGroupById(item.track);
    const source_url = item.source;
    console.log(`Selezionato item ${itemId} su gruppo ${track.id} con sorgente::: ${source_url}`);
    console.log(`Item select sul tempo: ${time} Evento:${e}`);

    this.setState({
      currentPositionDate : moment(time),
      selectedItem : item,
      selectedGroup: track,
      itemDraggingTime: null,
      draggingItem : null
    }, ()=>{
              console.log(`Dopo aggiornamento di stato il gruppo vale: ${track}`);
              //aggiorno l'insieme delle collisioni degli items
              this.updateItemCollisions(time);
              this.updateVideoCollisions(time,true);
             
    })
    
  }

loadVideo = (item, time) => 
  {
  // differenza di tempo in millisecondi
  const videoPos=  moment(time).diff(item.start_time);
  const videoPosInSeconds = parseInt(videoPos/1000);

  console.log(`Carico il video ${item.source} e lo posiziono al secondo ${videoPosInSeconds}`)
  this.changeVideoSource(item.source, videoPosInSeconds);
  }

// gestisce il click su un item (richiamato DOPO la callback onItemSelect)
handleItemClick = (itemId, e, time) =>
  { 
  

    const item = this.getItemById(itemId);
    const source_url = item.source;
    console.log(`Cliccato item ${itemId} del gruppo ${item.track} con sorgente::: ${source_url}`);
    console.log(`Item Click sul tempo: ${time} Evento:${e}`);
    const track = this.getGroupById(item.track)
    //console.log("Metto in pausa il player...");
    //this.player.pause();

    this.setState({
      currentPositionDate : moment(time),
      selectedItem : item,
      selectedGroup: track
      
    }, () =>{
              //aggiorno l'insieme delle collisioni degli items
              this.updateItemCollisions(time);
              // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
              this.updateVideoCollisions(time,true);
              
    })
  }

   

  // Verificare perchè questo metodo non viene mai chiamato
  handleCustomMarker = () =>
  {
    console.log("Marker cliccato");
  }
 
}

const RialeTimelineViewer = withTranslation()(RialeTimelineViewerNT); 
export default RialeTimelineViewer