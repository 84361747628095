import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory, createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer, RootState } from './reducers';
import { rootSaga } from './rootSaga';
// import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const history = createHashHistory({
  basename: window.location.pathname
});

const rootReducer = createRootReducer(history);

const configureStore = (initialState?: RootState): Store<RootState | undefined> => {
  const middlewares: any[] = [routerMiddleware(history), sagaMiddleware];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  return createStore(rootReducer, initialState, enhancer);
};

export const store = configureStore();
sagaMiddleware.run(rootSaga);

// export * from './types/auth'
// export * from './'


