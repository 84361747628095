import * as React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { Detail } from './pages/Detail'
import { SyncSession } from './pages/SyncSession'
import { PublicExperiment } from './pages/PublicExperiment'
import { LoginRequiredRoute } from './pages/LoginRequiredRoute';
import * as AuthSelector from './store/selectors/auth'

import { history } from './store';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from 'react-router-dom';

export const Routes = () => {

  const checked = useSelector(AuthSelector.isAuthChecked)
  const logged = useSelector(AuthSelector.getLoggedData)
  return (
    <>
      {checked ? (
        <>
          <ConnectedRouter history={history}>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/public/:id">
                  <PublicExperiment />
                </Route>
                <LoginRequiredRoute path="/detail/:id">
                  <Detail />
                </LoginRequiredRoute>
                <LoginRequiredRoute path="/sync/session/:id">
                  <SyncSession />
                </LoginRequiredRoute>
                <LoginRequiredRoute path="/">
                  <Home />
                </LoginRequiredRoute>
              </Switch>
          </ConnectedRouter>
        </>
      ) : (
          <div>Loading</div>
        )}
    </>
  )
};
