import React, { useState } from 'react';
import { Prompt } from 'react-router'
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import logo from '../logo.png';
// import footerLogo from '../footerLogo.png'
import * as Yup from 'yup';
import { Button, Progress, Dropdown,ButtonDropdown, CardTitle, CardHeader, CardBody, ModalHeader, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input, Navbar, NavItem, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, ModalFooter } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as ContainerActions from '../store/actions/container';
import * as UiActions from '../store/actions/ui';
// import * as AuthActions from '../store/actions/auth';
// import * as ProfileActions from '../store/actions/profile';
// import Amplify, { Auth } from 'aws-amplify';
// import { Link } from "react-router-dom";
import { PublicHeader } from "../components/PublicHeader"
import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {TrackType, TAGS_TRACK_ID} from '../components/Timeline/Constants';
import moment from 'moment';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import * as ContainerSelectors from '../store/selectors/container';
import { RouteConst } from '../consts/common';
import classnames from 'classnames';
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from 'react-icons/fi'
import { useLocation, useParams } from 'react-router-dom'

// import * as ContentSelectors from '../store/selectors/content';
import * as UiSelectors from '../store/selectors/ui';
import * as ProfileSelectors from '../store/selectors/profile';
import * as RouterSelectors from '../store/selectors/router';
import {
  PageWrapper,
  Title,
  StyledInlineErrorMessage,
  CodeWrapper,
  StyledButton,
  CenteredText,
  ImgFooter,
  OutlinedButton
} from "../styles/styles";
import { first_item, tracks } from '../components/Timeline/FakeItems';
import { ProfileTypes } from '../store/types/profile';
import { useTranslation } from 'react-i18next';
const defTimelineStart = moment().startOf("day").add(10,"hours")
const defTimelineDuration = null;

export const PublicExperiment: React.FC = () => {
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const container= useSelector(ContainerSelectors.getPublicExperiments)

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  let location= useLocation()
  let pathID= location.pathname.slice(8) //CONTROLLARE PATH DI PUBLIC....
    //  pathID='38a968e9-4dcb-447c-89ea-bcf22bc40661'
  // let pathExperiment= location.pathname
  // console.log("url url", pathExperiment, pathID)
  // pathExperiment='38a968e9-4dcb-447c-89ea-bcf22bc40661'
  const dispatch = useDispatch();
  console.log("dispatch")
  React.useEffect(()=>{ 
    dispatch(ContainerActions.willGetPublicExperiments(pathID))
    console.log('in useeffect')
  },[])

  const timelineStart= null
  const timelineDuration= defTimelineDuration

  let experiments=[]as any
  if(container){
      experiments= container.experimentsjson
  console.log("esperimenti pubblici: ", experiments, container)

  }
  const [activeTab, setActiveTab] = useState('0');
  
  React.useEffect(()=>{ 
  console.log('in map path', pathID.length, pathID, experiments)
    if (pathID.length > 2){
      experiments.forEach((value: any, i:number) => {
        console.log("in map 1", i, value.experiment, pathID)
        if(value.experiment== pathID){
                toggleNav(`${i}`)
                console.log("in map", i, value.experiment)
        }
      })
    }else if(experiments.length>0){
      console.log('in map experiment   ', experiments)
      dispatch(push({ pathname: RouteConst.Public+"/"+pathID+ '/' + experiments[0].experiment}))    }
  },[experiments])

const toggleNav = (tab: React.SetStateAction<string>) => {
    if(activeTab !== tab) {setActiveTab(tab);
    dispatch(push({ pathname: RouteConst.Public+"/"+experiments[activeTab].container+ '/' + experiments[parseInt(tab as string)].experiment}))
    }
  }

const dropDownExperiments=  experiments.map((value: any, i:number) => {
  return (
    <NavItem>
      <NavLink
            className={classnames({ active: activeTab === `${i}` })}
            onClick={() => { toggleNav(`${i}`); }}
          >
            {value.title}{'   '}
      </NavLink>
    </NavItem>
  )})

  return (
    
    <BlockUi tag="div"  loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus===true)}>
    <div className="App">
      <PublicHeader />
      <div>
      <Nav tabs>
          {dropDownExperiments}
      </Nav>
    </div>
    
    {(activeTab && experiments[activeTab]) ? (
      
      <div>
        <Row>
          <Col>
          <Card body className="text-center">
        <CardHeader tag="h4">{experiments[activeTab].title}: {experiments[activeTab].description}</CardHeader>
          </Card>
          </Col>
        </Row>
        
      <RialeTimelineViewer items={experiments[activeTab].items} 
                                    tracks={experiments[activeTab].tracks} 
                                    startDateTime={timelineStart}
                                    // duration={timelineDuration}
                                    title={experiments[activeTab].title}
                                    canEdit={false}
                                    />
                                    </div>
    ):(<></>)}
      <div>
      {/* <Footer /> */}
    </div>   
    </div>
    </BlockUi> 
  );
}