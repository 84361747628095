export default `
query listExperiments($container:ID!, $nextToken: String){
    listExperiments(container:$container, nextToken:$nextToken){
        experiments{
            meta
            title
            description
            experiment
            container 
            model
            tags
            tracks
            items
            owner
        }
        nextToken
   } 
}
`