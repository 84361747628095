import moment from 'moment';

import {TrackType, TAGS_TRACK_ID} from './Constants';
import {getTimelineItems} from './Utils';
    //const video_giua_url="https://itismgiua-my.sharepoint.com/personal/zdedda_itismgiua_onmicrosoft_com/Documents/RIALE%20Multimedia/Video/S1070001.MP4"
    //const video_trailer =  "https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
  

    export const FAKE_IOT_ITEM_START_TIME = moment("2020-04-27 10:35:00");

    const VIDEO_TRACK_ID = 10;
    const DOC_TRACK_ID = 20;
    const IOT_TRACK_ID = 30;

    const DOC_BGCOLOR = 'rgb(160,147,243)';
    const DOC_COLOR = 'rgb(255,255,255)';
     
   export const get_fake_iot_item = (startPos) =>
   {
     console.log("Creazione item IOT di test...");
     if (startPos==null) startPos = FAKE_IOT_ITEM_START_TIME;

     let item =  {
      id: 123456789,
      track: IOT_TRACK_ID,
      type: TrackType.IOT,
      title: 'Rilevamento della temperatura',
      description: "Rilevamento dei campioni di temperatura",
      start_time: moment(startPos).add(10, "minutes"),
      end_time: moment(startPos).add(20, 'minutes'),
      source : "http://localhost:3000/iot_data/temperatura_2_saw_tooth_noise.json",
      //source :"http://www.json-generator.com/api/json/get/bODXEzLzxe?indent=2"
    } 
    return getTimelineItems([item],true)[0];
   }

   export const get_fake_iot_data = (currentPos, numValues, freq_ms) => 
    {
      let iotData = [];
      for (let i=0;i<numValues;i++)
      {
        const newData = {
          date: moment(currentPos).add(i*freq_ms,"milliseconds"),
          temperatura :(Math.random()*50 + 10).toFixed(1),
          umidita :  (Math.random()*100).toFixed(1),
          //velocita :  (Math.random()*100).toFixed(1)
        }
        iotData.push(newData);
      }
      return iotData;
    } 


    export const tracks = [
                      { id: TAGS_TRACK_ID, title: 'Tag', type: TrackType.TAG, stackItems:true },            
                      { id: VIDEO_TRACK_ID, title: 'Canali video', type: TrackType.VIDEO,  stackItems:true },
                      { id: IOT_TRACK_ID, title: 'IoT Canali dati', type: TrackType.IOT,stackItems:true},
                      { id: DOC_TRACK_ID, title: 'Contenuto aggiuntivo', type: TrackType.DOC,stackItems:true }, 
                     
                    ];
    
    export const first_item = [
      {
        id: 1000,
        track: TAGS_TRACK_ID,
        type: TrackType.TAG,
        color:'rgb(255,255,255)',
        bgColor:'rgb(255,0,0)',
        title: 'Introduzione',
        description: "TAG INIZIALE",
        start_time: moment().startOf("day").add(10, 'hours'),
        end_time: moment().startOf("day").add(10, 'hours').add(5,'minutes'),
        source : "",
      } 
    ]           

     export const items = [
      {
        id: 1000,
        track: TAGS_TRACK_ID,
        type: TrackType.TAG,
        color:'rgb(255,255,255)',
        bgColor:'rgb(255,0,0)',
        title: 'Introduzione',
        description: "Fase introduttiva alla sperimentazione",
        start_time: moment().startOf("day").add(10, 'hours'),
        end_time: moment().startOf("day").add(10, 'hours').add(5,'minutes'),
        source : "",
      },
        {
          id: 1001,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(0,0,0)',  
          title: 'Inizio esperimento:preparazione librerie',
          description: "Fase iniziale: predisposizione delle librerie",
          start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(29,'minutes'),
          source : "",
        },
     
        {
          id: 1002,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(180, 195,26)',
          title: 'tagment genomic DNA',
          description: "Tagmentazione",
          start_time: moment().startOf("day").add(10, 'hours').add(17,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(25,"minutes"),
          source : "",
        },
     
        {
          id: 2001,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 1 - Preparazione iniziale',
          description: "Video che descrive la fase iniziale dell'esperimento: predisposizione delle librerie",
          start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
          duration : 742,
          start_offset : 0,
          end_offset :600,
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/1SureSelect_+Agilent+SureSelect+QXT+Library+Prep+Workflow.mp4",
        
        },
      
        {
          id: 2011,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 1 BIS - Preparazione iniziale',
          description: "Video che descrive la fase iniziale dell'esperimento: predisposizione delle librerie",
          start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
          duration : 742,
          start_offset : 0,
          end_offset :600,
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/1SureSelect_+Agilent+SureSelect+QXT+Library+Prep+Workflow.mp4",
        
        },
       
      /*
      {
        id: 20811,
        track: VIDEO_TRACK_ID,
        type: TrackType.VIDEO,
        title: 'J.S.Bach - Aria sulla quarta corda',
        description: "Aria sulla quarta corda BWV 1068",
        start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
        duration : 339,
        start_offset : 0,
        end_offset :339,
        source:"https://youtu.be/rrVDATvUitA"
      },

      {
        id: 20812,
        track: VIDEO_TRACK_ID,
        type: TrackType.VIDEO,
        title: 'J.S.Bach 2 - Aria sulla quarta corda',
        description: "Aria sulla quarta corda BWV 1068",
        start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
        duration : 339,
        start_offset : 0,
        end_offset :339,
        source:"https://youtu.be/rrVDATvUitA"
      },
       

     
        {
          id: 2021,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 1 bis - Preparazione iniziale',
          description: "Video in Sync che descrive la fase iniziale dell'esperimento: predisposizione delle librerie",
          start_time: moment().startOf("day").add(10, 'hours').add(16,"minutes"),
          duration : 807, //742,
          start_offset : 5,
          end_offset :630,
          //source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/1SureSelect_+Agilent+SureSelect+QXT+Library+Prep+Workflow.mp4",
          source: "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/3videoplayback.mp4"
          
        },
        
        */

        {
          id: 502,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Tagmentazione',
          description: "Documento sulla tagmentazione",
          start_time: moment().startOf("day").add(10, 'hours').add(17,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(20,'minutes'),
          //source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/timeline/Files/04++tagmentazione+DNA.docx"
        
        },
         

        {
          id: 503,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Vortex mixer',
          description: "Documento sul Vorter Mixer",
          start_time: moment().startOf("day").add(10, 'hours').add(19,"minutes").add(59,"seconds"),
          end_time: moment().startOf("day").add(10, 'hours').add(23,'minutes'),
          source:"https://en.wikipedia.org/wiki/Vortex_mixer"
        },
        {
          id: 504,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Termociclatore',
          description: "Documento sul termociclatore",
          start_time: moment().startOf("day").add(10, 'hours').add(19,"minutes").add(59,"seconds"),
          end_time: moment().startOf("day").add(10, 'hours').add(23,'minutes'),
          source:"https://it.wikipedia.org/wiki/Termociclatore"
        },

        {
          id: 1003,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(0,0,0)',  
          title: 'Purificazione e amplificazione',
          description: "Purificazione e amplificazione",
          start_time: moment().startOf("day").add(10, 'hours').add(30,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(40,"minutes"),
          source : "",
        },
        {
          id: 603,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Vortex mixer',
          description: "Documento sul Vorter Mixer",
          start_time: moment().startOf("day").add(10, 'hours').add(32,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(35,'minutes'),
          source:"https://en.wikipedia.org/wiki/Vortex_mixer"
        },
        {
          id: 604,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Centrifuga',
          description: "Documento sulla centrifuga",
          start_time: moment().startOf("day").add(10, 'hours').add(33,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(37,'minutes'),
          source:"https://it.wikipedia.org/wiki/Centrifuga_(tecnologie_chimiche)"
        },

        {
          id: 23,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 2 - Purificazione e amplificazione delle librerie',
          duration: 1472,
          start_offset:12,
          end_offset:1472,
          description: 'Video sulla purificazione e amplificazione delle librerie',
          start_time: moment().startOf("day").add(10, 'hours').add(30,"minutes"),
          
          source:"https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/2SureSelect_+All+Videos+in+One.mp4"
        },
       

        {
          id: 1004,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(0,0,0)',  
          title:"Verifica quantità e qualità librerie",
          description: 'Verifica qualitativa e quantitativa delle librerie',
          start_time: moment().startOf("day").add(10, 'hours').add(55,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(4,"minutes"),
          source : "",
        },
        {
          id: 605,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Fluorocromo',
          description: "Documento sul Fluorocromo",
          start_time: moment().startOf("day").add(10, 'hours').add(58,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(63,'minutes'),
          source:"https://it.wikipedia.org/wiki/Fluorocromo"
        },
        {
          id: 606,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Laser',
          description: "Documento sul laser",
          start_time: moment().startOf("day").add(10, 'hours').add(58,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(63,'minutes'),
          source:"https://it.wikipedia.org/wiki/Laser"
        },
        {
          id: 607,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'CCD',
          description: "Documento sul CCD",
          start_time: moment().startOf("day").add(10, 'hours').add(58,"minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(63,'minutes'),
          source:"https://it.wikipedia.org/wiki/Dispositivo_ad_accoppiamento_di_carica"
        },
        {
          id: 804,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 3 - Verifica qualità librerie',
          duration: 807,
          start_offset:0,
          end_offset:807,
          description: 'Video sulla verifica della qualità delle librerie',
          start_time: moment().startOf("day").add(10, 'hours').add(55,"minutes"),
          source: "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/3videoplayback.mp4"
          
        },

        {
          id: 1005,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(26,195,154)',
          title: "Caricamento e avvio scansione",
          description: 'Avvio della scansione',
          start_time: moment().startOf("day").add(11, 'hours').add(5,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(16,"minutes"),
          source : "",
        },
        {
          id: 1006,
          track: TAGS_TRACK_ID,
          type: TrackType.TAG,
          color:'rgb(255,255,255)',
          bgColor:'rgb(255,0,0)',
          title: 'Acquisizione dati',
          description: "Acquisizione dati",
          start_time: moment().startOf("day").add(11, 'hours').add(9,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(15,"minutes"),
          source : "",
        },
        {
          id: 3001,
          track: IOT_TRACK_ID,
          type: TrackType.IOT,
          color:'rgb(255,255,255)',
          bgColor:'rgb(55,120,40)',
          title: 'Campione 1',
          description: "Output dei campioni",
          start_time: moment().startOf("day").add(11, 'hours').add(9,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(14,"minutes"),
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/timeline/Files/SANTORO_2020-02-266.jpg"
          
        },

        {
          id: 805,
          track: VIDEO_TRACK_ID,
          type: TrackType.VIDEO,
          title: 'Video 4 - Scansione dei campioni',
          duration: 885,
          start_offset:0,
          end_offset:885,
          description: 'Video sulla scansione dei campioni',
          start_time: moment().startOf("day").add(11, 'hours').add(9,"minutes"),
          source: "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/videos/4videoplayback.mp4"
        },
        {
          id: 608,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Engauge Digitizer Videoguida',
          description: "Videoguida sull'Engauge Digitizer",
          start_time: moment().startOf("day").add(11, 'hours').add(8,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(15,'minutes'),
          source:"https://www.youtube.com/watch?v=CMlQXQhL2m4"
        },
        {
          id: 609,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Engauge per Windows',
          description: "Videoguida sull'Engauge per piattaforma Windows",
          start_time: moment().startOf("day").add(11, 'hours').add(9,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(17,'minutes'),
          source:"https://www.youtube.com/watch?v=CMlQXQhL2m4"
        },
        {
          id: 610,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Scala lineare e logaritmica',
          description: "Appunti sulle scale lineare e logaritmica",
          start_time: moment().startOf("day").add(11, 'hours').add(10,"minutes"),
          end_time: moment().startOf("day").add(11, 'hours').add(19,'minutes'),
          source:"https://www.youmath.it/domande-a-risposte/view/8091-scala-logaritmica.html"
        },



        // DOCS
        {
          id: 1,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Step del protocollo',
          description: "Descrizione del protocollo",
          start_time: moment().startOf("day").add(10, 'hours').add(1, "minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(8,'minutes'),
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/timeline/Files/01Descrizione+step+protocollo.docx"
        },
        {
          id: 2,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Strumenti',
          description: "Strumenti e materiali",
          start_time: moment().startOf("day").add(10, 'hours').add(1, "minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(6,'minutes'),
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/timeline/Files/02Strumenti+e+materiali.docx"
        },
        {
          id: 3,
          track: DOC_TRACK_ID,
          type: TrackType.DOC,
          color:DOC_COLOR,
          bgColor:DOC_BGCOLOR,
          title: 'Glossario',
          description: "Glossario dei termini",
          start_time: moment().startOf("day").add(10, 'hours').add(1, "minutes"),
          end_time: moment().startOf("day").add(10, 'hours').add(6,'minutes'),
          source : "https://740820033840-idea-resources-site-dev.s3-eu-west-1.amazonaws.com/docs/timeline/Files/03+Glossario.docx"
        },



      ]

     
      /* Modellazione Item Backend 
      interface Item {
    item: String
    track: String
    title: String
    description: String
    start_time: AWSDateTime
    source: String
    duration: Timestamp
}

type VideoItem implements Item {
    videoitem: String
    track: String
    title: String
    start_time: AWSDateTime
    source: String
    duration: Timestamp

    start_offset: Timestamp
    end_offset: Timestamp
    video_data: String
}
*/