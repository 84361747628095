import * as React from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Button } from 'reactstrap'
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

var logo=require('../idea.png');

export const PublicHeader: React.FC = () => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const dispatch = useDispatch();

  return (
    <Navbar color="faded" >
      <NavbarBrand className="mr-auto" target="_blank" onClick={() => { dispatch(push('/')) }}>   
              <img src={logo} height="80" style={{ display: "inline-block" , marginRight: "10px"}} />
              <h3 style={{ display: "inline-block", color: "black" }} ><strong>RIALE</strong></h3>
      </NavbarBrand>
    </Navbar>

  )
}