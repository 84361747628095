import * as ContainerTypes from '../types/container';
import { string } from 'yup';

export type WillAddContainer = (
  title: string,
  description: string,
  start_date: Date,
  end_date: Date
  ) => ContainerTypes.IWillAddContainer;

  export const willAddContainer: WillAddContainer = (
    title: string,
    description: string,
    start_date: Date,
    end_date: Date
  ) => ({
    title,
    description,
    start_date,
    end_date,
    type: ContainerTypes.ContainerTypes.WILL_ADD_CONTAINER
  });
  
  export type WillGetContainers = (
  ) => ContainerTypes.IWillGetContainers;

  export const willGetContainers: WillGetContainers = (
  ) => ({
    type: ContainerTypes.ContainerTypes.WILL_GET_CONTAINERS
  });

  export type DidGetContainers = (
    containers: any
    ) => ContainerTypes.IDidGetContainers;
  
    export const didGetContainers: DidGetContainers = (
      containers: any
    ) => ({
      containers,
      type: ContainerTypes.ContainerTypes.DID_GET_CONTAINERS
    });
  
    export type DidGetSyncSessions = (
      sessions: any
      ) => ContainerTypes.IDidGetSyncSessions;

    export const didGetSyncSessions: DidGetSyncSessions = (
      sessions: any
    ) => ({
      sessions,
      type: ContainerTypes.ContainerTypes.DID_GET_SYNC_SESSIONS
    });

  
    export type WillSendSyncCommand = (
      sessions: any
      ) => ContainerTypes.IWillSendSyncCommand;

    export const willSendSyncCommand: WillSendSyncCommand = (
      command: any
    ) => ({
      command,
      type: ContainerTypes.ContainerTypes.WILL_SEND_SYNC_COMMAND
    });

    export type WillGetExperiments = (
      container: string
      ) => ContainerTypes.IWillGetExperiments;
    
    export const willGetExperiments: WillGetExperiments = (
      container: string
    ) => ({
      container,
      type: ContainerTypes.ContainerTypes.WILL_GET_EXPERIMENTS
    });
    
    export type DidGetExperiments = (
      experiments: any
      ) => ContainerTypes.IDidGetExperiments;
    
    export const didGetExperiments: DidGetExperiments = (
      experiments: any
    ) => ({
      experiments,
      type: ContainerTypes.ContainerTypes.DID_GET_EXPERIMENTS
    });

    export type DidAddElement = (
      element: any,
      experimentIndex: number,
      experiments: any,
      event: string
      ) => ContainerTypes.IDidAddElement;
    
    export const didAddElement: DidAddElement = (
      element: any,
      experimentIndex: number,
      experiments: any,
      event: string
    ) => ({
      element,
      experimentIndex,
      experiments,
      event,
      type: ContainerTypes.ContainerTypes.DID_ADD_ELEMENT
    });

    export type WillSaveExperiment = (
      experimentIndex: number
      ) => ContainerTypes.WillSaveExperiment;
    
    export const willSaveExperiment: WillSaveExperiment = (
      experimentIndex: number
    ) => ({
      experimentIndex,
      type: ContainerTypes.ContainerTypes.WILL_SAVE_EXPERIMENT
    });

    export type WillAddExperiment = (
      title: string,
      description: string,
      container: string
      ) => ContainerTypes.IWillAddExperiment;
    
      export const willAddExperiment: WillAddExperiment = (
        title: string,
        description: string,
        container: string
      ) => ({
        title,
        description,
        container,
        type: ContainerTypes.ContainerTypes.WILL_ADD_EXPERIMENT
      });

      export type WillDeleteExperiment = (
        experiment: string,
        container: string
        ) => ContainerTypes.IWillDeleteExperiment;
      
        export const willDeleteExperiment: WillDeleteExperiment = (
          experiment: string,
          container: string
        ) => ({
          experiment,
          container,
          type: ContainerTypes.ContainerTypes.WILL_DELETE_EXPERIMENT
        });

        export type WillCloneContainer = (
          container: string,
          owner: string
          ) => ContainerTypes.IWillCloneContainer;
        
          export const willCloneContainer: WillCloneContainer = (
            container: string,
            owner: string
          ) => ({
            container,
            owner,
            type: ContainerTypes.ContainerTypes.WILL_CLONE_CONTAINER
          });

      export type WillEditContainer = (
        title: string,
        description: string,
        start_date: Date,
        end_date: Date,
        container: string,
        visibility: string
        ) => ContainerTypes.IWillEditContainer;
      
        export const willEditContainer: WillEditContainer = (
          title: string,
          description: string,
          start_date: Date,
          end_date: Date,
          container: string,
          visibility: string
        ) => ({
          title,
          description,
          start_date,
          end_date,
          container,
          visibility,
          type: ContainerTypes.ContainerTypes.WILL_EDIT_CONTAINER
        });

      export type WillDeleteContainer = (
        container: string
        ) => ContainerTypes.IWillDeleteContainer;
      
      export const willDeleteContainer: WillDeleteContainer = (
        container: string
      ) => ({
        container,
        type: ContainerTypes.ContainerTypes.WILL_DELETE_CONTAINER
      });

      export type WillUploadFile = (
        url: any
        ) => ContainerTypes.IWillUploadFile;
      
      export const willUploadFile: WillUploadFile = (
        url: any
      ) => ({
        url,
        type: ContainerTypes.ContainerTypes.WILL_UPLOAD_FILE
      });

      export type WillGetPublicExperiments = (
        container: string
        ) => ContainerTypes.IWillGetPublicExperiments;
      
      export const willGetPublicExperiments: WillGetPublicExperiments = (
        container: string
      ) => ({
        container,
        type: ContainerTypes.ContainerTypes.WILL_GET_PUBLIC_CONTAINER
      });

      export type WillPublishContainer = (
        container: string
        ) => ContainerTypes.IWillPublishContainer;
      
      export const willPublishContainer: WillPublishContainer = (
        container: string
      ) => ({
        container,
        type: ContainerTypes.ContainerTypes.WILL_PUBLISH_CONTAINER
      });

      export type DidGetPublicContainer = (
        containers: any
        ) => ContainerTypes.IDidGetPublicContainers;
      
        export const didGetPublicContainer: DidGetPublicContainer = (
          containers: any
        ) => ({
          containers,
          type: ContainerTypes.ContainerTypes.DID_GET_PUBLIC_CONTAINER
        });