export default `
query listPublicContainers($nextToken: String){
    listPublicContainers(nextToken:$nextToken){
        containers{
            meta
            visibility
            container
            title
            created
            modified
            description
            start_time
            end_time
            owner
            deleted
        }
        nextToken
    }


}
`