
import React, {Component} from 'react';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { BsPip } from "react-icons/bs";
import { MdHearing } from "react-icons/md";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { RiFullscreenLine } from "react-icons/ri";
import { withTranslation } from 'react-i18next';

class ControlsPanelNT extends Component
{
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.volume!=this.props.volume)
        {
            ReactTooltip.rebuild();
        }
    }

    render()
    {
        const {volume, muted,audioSolo,t} = this.props;

        const audioSoloColor = (!audioSolo ? "white" : (muted ? "red" : "green"));

        return ( <div style={{ position: 'absolute',
        bottom: "0px",
        right: "0px",
        backgroundColor:"darkgrey",
        opacity:"0.8",
        borderStyle: "",
        width:"100%",
        zIndex:200}} >

          <IconButton onClick={()=> {this.props.onToggleMute()}}  style={{
            backgroundColor:"black", 
            opacity:'1',
            margin:'3px'
            
           }}>
               <IconContext.Provider value={{color: "white", size:"0.40em"}}>
                   {volume<=0 ? 
                   <FaVolumeMute data-place="top" data-tip={t("tl:tip_audioOff")} /> :
                   <FaVolumeUp data-place="top" data-tip={t("tl:tip_audioOn")}  /> }
                  

               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onToggleAudioSolo()}  style={{
            backgroundColor:`${audioSoloColor}`, 
            opacity:'1',
            margin:'3px',
           }}>
               <IconContext.Provider value={{color: "black", size:"0.40em"}}>
                  <MdHearing data-place="top" data-tip={t("tl:tip_muteOthers")}  />
               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onFullScreenRequest()}  style={{
            backgroundColor:"black", 
            opacity:'1',
            margin:'3px'
           }}>
               <IconContext.Provider value={{color: "white", size:"0.40em"}}>
                  <RiFullscreenLine data-place="top" data-tip={t("tl:tip_fullscreen")} />
               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onTogglePip()}  style={{
            backgroundColor:"black", 
            margin:'3px'
            
           }}>
               <IconContext.Provider value={{color: "white", size:"0.40em"}}>
                   <BsPip data-place="top" data-tip={t("tl:tip_pip")} /> 
               </IconContext.Provider>
         </IconButton>
         
         <ReactTooltip place="top"/>
         </div>)
    }
}

const ControlsPanel = withTranslation()(ControlsPanelNT); 
export default ControlsPanel;