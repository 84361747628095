import * as AuthTypes from '../types/auth';
import { string } from 'yup';

export type OnFulfillFormAction = (key: string, value: string) => AuthTypes.IOnFulfillFormType;

// export type WillSaveToken =(
//   userData: any
// ) => AuthTypes.IWillSaveToken;

// export const willSaveTokenAction: WillSaveToken=(
//   userData: any
// ) =>({
//   userData,
//   type: AuthTypes.AuthTypes.WILL_SAVE_TOKEN
// })
export type WillCheckIfAlreadLoggedIn = (
  path: string
) => AuthTypes.WillCheckIfAlreadLoggedIn;

export const WillCheckIfAlreadLoggedIn: WillCheckIfAlreadLoggedIn = (
  path: string
) => ({
  path,
  type: AuthTypes.AuthTypes.WILL_CHECK_IF_LOGGEDIN
});

export type WillForgotPasswordConfirm = (
  email: string,
  verificationCode: string,
  password: string
) => AuthTypes.IWillForgotPasswordConfirm;

export const willForgotPasswordConfirmAction: WillForgotPasswordConfirm = (
  email: string,
  verificationCode: string,
  password: string
) => ({
  email,
  verificationCode,
  password,
  type: AuthTypes.AuthTypes.WILL_FORGOT_PASSWORD_CONFIRM
});

export type WillUpdateEthereumAddress = (
  ethereum: string
) => AuthTypes.IWillUpdateEthereumAddress;

export const willUpdateEthereumAddress: WillUpdateEthereumAddress = (
  ethereum: string
) => ({
  ethereum,
  type: AuthTypes.AuthTypes.WILL_UPDATE_ETHEREUM_ADDRESS
});

export type DidForgotPasswordConfirmSucceededAction = (
  data: any
) => AuthTypes.IDidForgotPasswordConfirmSucceeded;

export const didForgotPasswordConfirmSucceededAction: DidForgotPasswordConfirmSucceededAction = (
  data: any
) => ({
  data,
  type: AuthTypes.AuthTypes.DID_FORGOT_PASSWORD_CONFIRM_SUCCEEDED
});

export type DidForgotPasswordConfirmFailedAction = (
  error: any
) => AuthTypes.IDidForgotPasswordConfirmFailed;

export const didForgotPasswordConfirmFailedAction: DidForgotPasswordConfirmFailedAction = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_FORGOT_PASSWORD_CONFIRM_FAILED
});

export type WillForgotPassword = (
  email: string
) => AuthTypes.IWillForgotPassword;

export const willForgotPasswordAction: WillForgotPassword = (
  email: string
) => ({
  email,
  type: AuthTypes.AuthTypes.WILL_FORGOT_PASSWORD
});

export type DidForgotPasswordSucceededAction = (
  data: any
) => AuthTypes.IDidForgotPasswordSucceeded;

export const didForgotPasswordSucceededAction: DidForgotPasswordSucceededAction = (
  data: any
) => ({
  data,
  type: AuthTypes.AuthTypes.DID_FORGOT_PASSWORD_SUCCEEDED
});

export type DidForgotPasswordFailedAction = (
  error: any
) => AuthTypes.IDidForgotPasswordFailed;

export const didForgotPasswordFailedAction: DidForgotPasswordFailedAction = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_FORGOT_PASSWORD_FAILED
});

export type WillSignInAction = (
  email: string,
  password: string
) => AuthTypes.IWillSignIn;

export const willSignInAction: WillSignInAction = (
  email: string,
  password: string
) => ({
  email,
  password,
  type: AuthTypes.AuthTypes.WILL_SIGNIN
});

export type DidSignInSuceededAction = (
  data: any
) => AuthTypes.IDidSignInSucceeded;

export const didSignInSuceededAction: DidSignInSuceededAction = (
  data: any
) => ({
  data,
  type: AuthTypes.AuthTypes.DID_SIGNIN_SUCCEEDED
});

export type WillSignoutAction = (
) => AuthTypes.WillSignoutAction;

export const willSignoutAction: WillSignoutAction = (
) => ({
  type: AuthTypes.AuthTypes.WILL_SIGN_OUT
});

export type DidSignoutAction = (
  ) => AuthTypes.DidSignoutAction;
  
  export const didSignoutAction: DidSignoutAction = (
  ) => ({
    type: AuthTypes.AuthTypes.DID_SIGN_OUT
  });

export type WillSignUpAction = (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => AuthTypes.IWillSignUp;

export const willSignUpAction: WillSignUpAction = (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => ({
  firstName,
  lastName,
  email,
  password,
  type: AuthTypes.AuthTypes.WILL_SIGNUP
});
export const onFulfillFormAction: OnFulfillFormAction = (key: string, value: string) => ({
  key,
  value,
  type: AuthTypes.AuthTypes.ON_FULFILL_FORM
});

export type WillConfirmSignUp = (
  username: string,
  code: string
) => AuthTypes.WillConfirmSignUp;

export const willConfirmSignUp: WillConfirmSignUp = ( username:string, code:string)=>({
  username,
  code,
  type:AuthTypes.AuthTypes.WILL_CONFIRM_SIGN_UP
});

export type DidCheckLoginWithSuccess = (
  data: boolean
) => AuthTypes.IDidLoginCheckedWithSuccess;

export const didCheckLoginWithSuccess: DidCheckLoginWithSuccess = ( data:boolean)=>({
  data,
  type:AuthTypes.AuthTypes.DID_LOGIN_CHECKED_WITH_SUCCESS
});