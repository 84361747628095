export enum AuthTypes {
    ON_FULFILL_FORM = 'auth/ON_FULFILL_FORM',
    WILL_SIGNUP = 'auth/WILL_SIGNUP',
    WILL_SIGNIN = 'auth/WILL_SIGNIN',
    DID_SIGNIN_SUCCEEDED = 'auth/DID_SIGNIN_SUCCEEDED',
    WILL_FORGOT_PASSWORD = 'auth/WILL_FORGOT_PASSWORD',
    DID_FORGOT_PASSWORD_SUCCEEDED = 'auth/DID_FORGOT_PASSWORD_SUCCEEDED',
    DID_FORGOT_PASSWORD_FAILED = 'auth/DID_FORGOT_PASSWORD_FAILED',
    WILL_FORGOT_PASSWORD_CONFIRM = 'auth/WILL_FORGOT_PASSWORD_CONFIRM',
    DID_FORGOT_PASSWORD_CONFIRM_SUCCEEDED = 'auth/DID_FORGOT_PASSWORD_CONFIRM_SUCCEEDED',
    DID_FORGOT_PASSWORD_CONFIRM_FAILED = 'auth/DID_FORGOT_PASSWORD_CONFIRM_FAILED',
    WILL_CONFIRM_SIGN_UP='auth/WILL_CONFIRM_SIGN_UP',
    WILL_UPDATE_ETHEREUM_ADDRESS = 'auth/WILL_UPDATE_ETHEREUM_ADDRESS',
    WILL_CHECK_IF_LOGGEDIN = 'auth/WILL_CHECK_IF_LOGGEDIN',
    WILL_SIGN_OUT= 'auth/WILL_SIGN_OUT',
    DID_SIGN_OUT= 'auth/DID_SIGN_OUT',
    DID_LOGIN_CHECKED_WITH_SUCCESS = 'auth/DID_LOGIN_CHECKED_WITH_SUCCESS'
    // WILL_SAVE_TOKEN='auth/WILL_SAVE_TOKEN'
  }

  // export interface IWillSaveToken {
  //   readonly type: AuthTypes.WILL_SAVE_TOKEN;
  //   readonly userData: any
  // }
  export interface WillSignoutAction {
    readonly type: AuthTypes.WILL_SIGN_OUT;
  }
  export interface DidSignoutAction {
    readonly type: AuthTypes.DID_SIGN_OUT
  }

  export interface WillCheckIfAlreadLoggedIn {
    readonly type: AuthTypes.WILL_CHECK_IF_LOGGEDIN;
    readonly path: string
  }
  
  export interface IWillUpdateEthereumAddress {
    readonly type: AuthTypes.WILL_UPDATE_ETHEREUM_ADDRESS;
    readonly ethereum: string
  }

  export interface IOnFulfillFormType {
    readonly type: AuthTypes.ON_FULFILL_FORM;
    readonly key: string;
    readonly value: string;
  }
  
  export interface IWillSignUp {
    readonly type: AuthTypes.WILL_SIGNUP;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly password: string;
  }
  
  export interface IWillSignIn {
    readonly type: AuthTypes.WILL_SIGNIN;
    readonly email: string;
    readonly password: string;
  }
  
  export interface IDidSignInSucceeded {
    readonly type: AuthTypes.DID_SIGNIN_SUCCEEDED;
    readonly data: any;
  }

  export interface IDidLoginCheckedWithSuccess {
    readonly type: AuthTypes.DID_LOGIN_CHECKED_WITH_SUCCESS;
    readonly data: boolean;
  }
 
  export interface IWillForgotPasswordConfirm {
    readonly type: AuthTypes.WILL_FORGOT_PASSWORD_CONFIRM;
    readonly email: string;
    readonly verificationCode: string;
    readonly password: string;
  }
  
  export interface IDidForgotPasswordConfirmSucceeded {
    readonly type: AuthTypes.DID_FORGOT_PASSWORD_CONFIRM_SUCCEEDED;
    readonly data: any;
  }
  
  export interface IDidForgotPasswordConfirmFailed {
    readonly type: AuthTypes.DID_FORGOT_PASSWORD_CONFIRM_FAILED;
    readonly error: any;
  }
  
  export interface IWillForgotPassword {
    readonly type: AuthTypes.WILL_FORGOT_PASSWORD;
    readonly email: string;
  }
  
  export interface IDidForgotPasswordSucceeded {
    readonly type: AuthTypes.DID_FORGOT_PASSWORD_SUCCEEDED;
    readonly data: any;
  }
  
  export interface IDidForgotPasswordFailed {
    readonly type: AuthTypes.DID_FORGOT_PASSWORD_FAILED;
    readonly error: any;
  }
  export interface WillConfirmSignUp {
    readonly type: AuthTypes.WILL_CONFIRM_SIGN_UP;
    readonly username:any;
    readonly code: any;
  }
  
  export interface IAuthInitialState {
    signup: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
    };
    login: {
      email: string;
      pwd: string;
    };
    logged: boolean;
    authData: any;
    errData: any;
    checked: boolean;
  }
  
  export type IAuthActionTypes =
    | IOnFulfillFormType
    | IWillSignUp
    | IWillSignIn
    | IDidSignInSucceeded
    | IWillForgotPassword
    | WillCheckIfAlreadLoggedIn
    | WillSignoutAction
    | DidSignoutAction
    | IDidLoginCheckedWithSuccess
    // | IWillSaveToken
  ;
  