//https://it.reactjs.org/docs/conditional-rendering.html
//https://stackoverflow.com/questions/58277981/how-to-create-a-3-column-layout-structure-in-react-js
//https://www.npmjs.com/package/react-contextmenu
//https://stackoverflow.com/questions/10970958/get-a-color-component-from-an-rgb-string-in-javascript
// dipendenze
//yarn add @material-ui/core
//yarn add @material-ui/icons
//yarn add rc-slider
//yarn add validate-image-url
//yarn add uuidv4
//yarn add react-modal-image
//yarn add react-datetime -> OK
//yarn add react-color

//https://github.com/react-component/slider
//https://www.npmjs.com/package/get-video-duration
//https://www.npmjs.com/package/validate-image-url

import { TimeDurationInput } from 'react-time-duration-input';
import {validator} from './Utils';
import { uuid } from 'uuidv4';
import { withTranslation } from 'react-i18next';
import { TimeSeries } from "pondjs";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react';
import { Button,  Modal, ModalHeader, ModalBody, ModalFooter, CardTitle } from 'reactstrap';
import { Form, FormGroup, Label, Input,FormFeedback, FormText} from 'reactstrap';
import ReactPlayer from 'react-player';

import {
  Card, CardText, CardHeader, CardLink, CardImg} from 'reactstrap';

import Slider from 'rc-slider';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import {DefItemColors} from './Constants';
import Lightbox from 'react-image-lightbox';
import {FiUpload} from "react-icons/fi";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";

import DateTime from 'react-datetime';
import {TAGS_TRACK_ID, TrackType} from './Constants';

import moment from 'moment';
import { connect } from "react-redux";
import {willUploadFile} from '../../store/actions/container';
import {getUploadingState} from '../../store/selectors/ui'
import UploadingModal from '../../pages/Detail'
const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm:ss";


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

let awsConfig={}
if (process.env.REACT_APP_STAGE === 'production') {
  awsConfig = require('../../awsConfig-prod.json');
} else if(process.env.REACT_APP_STAGE === 'dev') {
  awsConfig = require('../../awsConfig-dev.json');
}else if (process.env.REACT_APP_STAGE === 'demo') {
  awsConfig = require('../../awsConfig-demo.json');
}else{
  awsConfig = require('../../awsConfig-integration.json');
}

 class ModalItemEditorNT extends Component  {

  constructor(props)
  {
    super(props);
   
    const item = this.props.item;
    
  if (item==null)
    {
      const start = moment(this.props.start).toDate();
      const end = this.props.track.type === TrackType.VIDEO ? start :moment(this.props.start).add(1,"hours").toDate();
      console.log(`In ModalItemEditor: end:${typeof end}`);
      const duration = moment(end).diff(moment(start),"seconds",true)
      this.state={ 
        currentDateStart : start,
        currentDateEnd : end,
        duration : duration*1000, // il componente gestisce i millisecondi,
        urlValue : "",
        usedUrlValue:"",
        titleValue :"",
        descriptionValue:"",
        currentDuration:duration*1000, // espressa in millisecondi!
        startOffset : 0,
        currentStartOffset:0,
        endOffset : duration*1000,
        currentEndOffset : duration*1000,
        invalidUrl : true,
        invalidTitle : true,
        invalidDuration : false,
        invalidStartOffset: false,
        invalidEndOffset : false,
        invalidStartDate : false,
        urlChecking : false,
        color : DefItemColors(this.props.track.type).color,
        bgColor : DefItemColors(this.props.track.type).bgColor,
        iotJsonDateUpdated: false, // indica se le date di inizio e fine sono state aggiornate in seguito alla lettura del JSON
        iotJsonDateUpdating: false, // indica se è in corso il parsing del Json per la verifica delle date (puo' impiegare diversi secondi)
        invalidJson: false
      }
    }
    else{
      this.state={ 
        currentDateStart : moment(item.start_time).toDate(),
        currentDateEnd :  moment(item.end_time).toDate(),
        duration: item.duration*1000, // il componente gestisce i millisecondi
        usedUrlValue:item.source,
        urlValue : item.source,
        titleValue : item.title,
        descriptionValue: item.description,
        currentDuration:item.duration*1000,
        startOffset : item.start_offset*1000,
        currentStartOffset: item.start_offset*1000,
        endOffset : item.end_offset*1000,
        currentEndOffset : item.end_offset*1000,
        invalidUrl: false,
        invalidTitle : false,
        invalidDuration: false,
        invalidStartOffset: false,
        invalidEndOffset : false,
        invalidStartDate : !moment(item.start_time).isValid(),
        urlChecking: false,
        color: item.color,
        bgColor: item.bgColor,
        iotJsonDateUpdated: false, // indica se le date di inizio e fine sono state aggiornate in seguito alla lettura del JSON
        iotJsonDateUpdating: false, // indica se è in corso il parsing del Json per la verifica delle date (puo' impiegare diversi secondi)
        invalidJson: false
      }
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploading !== this.props.uploading) {
      if(!this.props.uploading){
        console.log('upload ok ')
        this.handleUrlChange(this.state.urlValue)
      }
    }
  }
  
  handleVideoDuration = (duration) =>
  {
    console.log(`Richiamato handleVideoDuration  con duration: ${duration}`);
    // se non sto facendo una verifica della url, ignoro i cambiamenti 
    // di stato del video, per evitare che cancelli le impostazioni dei video
    // validi
    if (!this.state.urlChecking)
    return;

    if (duration==null || isNaN(duration))
      {
        this.setState({invalidUrl: true, duration : 0, startOffset:0, currentStartOffset:0,
          endOffset: 0, currentEndOffset:0}, 
          ()=>this.updateEndDatetime());
      }

      else
      {
        this.setState({invalidUrl: false, duration : duration*1000, startOffset:0, 
          endOffset: duration*1000, currentEndOffset: duration*1000, urlChecking:false}, 
          ()=>this.updateEndDatetime());
        }
  }

  handleVideoError = (error) =>
  {
    console.log(`Richiamato handleVideoError: ${error}`);

    // se non sto facendo una verifica della url, ignoro i cambiamenti 
    // di stato del video, per evitare che cancelli le impostazioni dei video
    // validi
    if (!this.state.urlChecking)
    return;

    console.log("Errore nel caricamento video");
    this.setState({invalidUrl: true, duration : 0, startOffset:0, currentStartOffset: 0, urlChecking:false,
      endOffset: 0, currentEndOffset:0}, 
      ()=>this.updateEndDatetime());
  }

  
  handleUrlChange = (event) =>
    {
      let url
      console.log("event url", event)
      if(event.target){
        url = event.target.value;
      }else{
        url=event
      }
      const valid = this.isValidUrl(url);
      console.log(`Validità dell'url: ${valid}`, this.props.track.type);
      
      this.setState({urlValue: url , invalidUrl: !valid, iotJsonDateUpdated:false });
      console.log('state valid url', this.state)
      // se la url modificata risulta valida
      // e ho a che fare con un item di tipo VIDEO
      // provo  a ricaricare i metadati del video
      // in modo da fare un ulteriore check sulla validità della url
      if (valid && this.props.track.type===TrackType.VIDEO)
      {
        try {
          console.log("Provo a vedere se posso caricare la url video....");
          if (!ReactPlayer.canPlay(url))
          { console.log("Non valida...");
            this.setState({urlValue: url , invalidUrl: true });
          }
          else
          {
            console.log("Url valida, mi accerto di leggere una durata di video valida...");
            this.setState({urlChecking: true, urlValue: url, usedUrlValue:url});
          }
          
        } catch(ex) 
          {
            console.log(`Eccezione nel tentativo di caricare la url:${ex}`);
            this.setState({urlValue: url , invalidUrl: !valid });
          }
      }
      // se ho a che fare con un item di tipo IOT provo a leggere la url
      // per vedere se si tratta di un json con informazioni temporali
      // sui campioni, in modo da suggerire il tempo iniziale e finale dell'item
      else if (valid && this.props.track.type===TrackType.IOT)
      {
        console.log("[TODO] Leggere i dati del Json IOT per recuperare info su range temporale dei campioni");
        this.setJsonDataByUrl(url);
      }
    }


  /** Verifica la validità del Form
   *  Un form è valido se ha una durata maggiore di 0, un titolo, dei valori di data corretti e una url valida (a parte i tag)
   *  Nel caso di item Video, si richiede anche che abbia lo startOffset < endOffset
   */
  isValidForm = () =>
   {
     const {invalidDuration,invalidTitle,invalidUrl, 
            invalidStartDate, invalidStartOffset, invalidEndOffset,
            iotJsonDateUpdating, invalidJson,
       startOffset, endOffset} = this.state;
       // tag
       let isValid = !invalidDuration && !invalidTitle && !invalidStartDate && 
                      !iotJsonDateUpdating;
     if (this.props.track.type!=TrackType.TAG)
      {
        isValid = isValid && !invalidJson && !invalidUrl && !invalidStartOffset && !invalidEndOffset && startOffset<endOffset;
      }
     return isValid;
   }

    closeModal = () => {
      console.log("Richiamata closeModal()");
      this.props.toggle();
    };

    isValidUrl = (string) => {
      let url;
      try {
        url = new URL(string);
            console.log('url path',url)

      } catch (_) {
        console.log( `URL NON VALIDO: ${string}`);
        return false;  
      }

      return url.protocol === "http:" || url.protocol === "https:" || url.protocol==="file:";
    }

    onChangeDateStart = (newDate) =>
    {
      console.log(`selezionata DateStart: ${newDate}`);
      try{
        const newDateStart =  moment(newDate,DATE_TIME_FORMAT);
        if (newDateStart.isValid())
        {
          this.setState({currentDateStart:newDateStart, invalidStartDate:false} , () => this.updateEndDatetime());
        }
        else 
        {
          console.log(`Invalid DateStart: ${newDate}`);
          this.setState({invalidStartDate:true});
        }
        
      } catch (error){
         console.log(`Invalid DateStart: ${newDate} -> ${error}`);
         this.setState({invalidStartDate:true});
      }
      
    }

    onChangeDateEnd = (newDate) =>
    {
      console.log(`selezionata DateEnd: ${newDate}`);
      //alert("tl:Cambiata data finale!");
      this.setState({currentDateEnd: moment(newDate,DATE_TIME_FORMAT)});
    }


    handleTitleChange = (event) =>
    {
      this.setState({titleValue: event.target.value, invalidTitle: event.target.value=="" });
    }

    handleDescriptionChange = (event) =>
    {
      this.setState({descriptionValue: event.target.value});
    }

    /** imposta la durata dell'item
     * n.b: questo metodo agisce solo su item NON di tipo video
     * in cui l'endOffset viene impostato come valore di duration
     * @param newValue nuova durata espressa in millisecondi
     */
    setDuration = (newValue) =>
    {
      console.log(`Valore di duration:${newValue}`);
      this.setState({duration:newValue, endOffset: newValue,
                      invalidDuration : newValue<=0}, 
                      () => this.updateEndDatetime());
    }

    setStartOffset = (newValue) =>
    {
      console.log(`Valore di startOffset:${newValue}`);
      this.setState({startOffset:newValue}, () => this.updateEndDatetime());
    }

    setEndOffset = (newValue) =>
    {
      console.log(`Valore di endOffset:${newValue}`);
      this.setState({endOffset:newValue}, () => this.updateEndDatetime());
    }

    onChangeOffsets = (newValue) => {
      console.log("Nuovo valore:", newValue);
      this.setState({startOffset:newValue[0], endOffset:newValue[1],
       currentStartOffset:newValue[0], currentEndOffset:newValue[1]
     }, () => this.updateEndDatetime());
  
   }

    updateEndDatetime = () =>
    {
      
      const {currentDateStart, startOffset, endOffset} = this.state;
      //console.log(`MIEDITOR:updateEndTime StartOffset: ${startOffset} endOffset: ${endOffset} itemEO:${this.props.item.end_offset}`);
      const currentDateEnd = moment(currentDateStart).add(moment.duration(endOffset,'milliseconds')).add(moment.duration(-startOffset,'milliseconds'))
      this.setState({currentDateEnd});        
    }

    deleteItem = () =>
    {
      const {
        onItemDeleted,
        item
      } = this.props;

      onItemDeleted(item);
      // chiusura della finestra
      this.closeModal();
    }

    /** Crea o modifica un item esistente sulla base degli inserimenti dell'utente */
    createItem = () =>
    {
      const {
        onItemEdited,
        item,
        track,
      } = this.props;

    
      const { titleValue, urlValue, descriptionValue, currentDateStart, duration,
        startOffset, endOffset, currentDateEnd, color, bgColor} = this.state;
      
      console.log(`Da createItem è stato passato il gruppo ${track.id} Duration:${duration}`);
      const new_item = {
                      id: (item==null ? uuid() : item.id),  
                      track: track.id,
                      color: color,
                      bgColor: bgColor,
                      type: track.type,
                      title: titleValue,
                      description: descriptionValue,
                      source : urlValue,
                      start_time: moment(currentDateStart),
                      duration: duration/1000.0, // l'item ha una durata espressa in secondi
                      end_time:  moment(currentDateEnd), 
                      canMove: true,
                      canResize : (track.type===TrackType.VIDEO || track.type===TrackType.IOT) ? false : "right",  
                      canChangeGroup :(track.type===TrackType.VIDEO || track.type===TrackType.TAG) ? false : true,
                      start_offset: startOffset/1000,
                      end_offset: endOffset/1000
                    }
                    
                    console.log(`MIEDITOR: salvo endOffset: ${endOffset}`)
                    // callback di creazione dell'item
                    onItemEdited(new_item);
                    // chiusura della finestra
                    this.closeModal();
    }


   handleColorChange = (color, event) =>
    {
      console.log(`Nuovo colore in ItemEditor: ${color}`);
      this.setState({color});
    }

  handleBgColorChange = (bgColor, event) =>
    {
      console.log(`Nuovo bgcolore in ItemEditor: ${bgColor}`);
      this.setState({bgColor});
    }
  toggleUpload = () => {
    this.setState({modalUpload : !this.state.modalUpload });
    }
      
     
  uploadFile = (file) => {
    // UPLOAD DEL FILE TIMELINE
    const x= this.props.willUploadFile(file);
    console.log('uploaded url file', x, this.props.uploading)
    if (x.url==null)
    {
      console.warn("Url di upload nulla in uploadFile. Non procedo con l'upload");
      this.setState({urlValue: null});
      //this.toggleUpload();
      return;
    }
    const filename= x.url.name
    const url= 'https://'+awsConfig.amplifyConfigure.Storage.AWSS3.bucket+'.s3-'+awsConfig.amplifyConfigure.Storage.AWSS3.region+'.amazonaws.com/public/'+filename.replace(/ /g,'+')
    // const url= 'https://740820033840-idea-riale-backend-dev-contents-bucket.s3-eu-west-1.amazonaws.com/private/'+localStorage.getItem('aws.cognito.identity-id.'+awsConfig.amplifyConfigure.Auth.identityPoolId)?.replace(/:/,'%3A')+'/'+filename.replace(/ /g,'+')
    // this.setState({urlValue: url})
    // const progress= this.props.getUpdateProgress();
    // console.log('uploaded url file', x, progress)
    this.setState({urlValue: url})
    this.toggleUpload()
    }

    setJsonDataByUrl = async (url) => 
    {
        if (url==null)
        {
            this.setState({iotJsonDateUpdated:false, invalidJson:true});
            return;
        }
        
        try {

            console.log(`Leggo il json dalla url -> ${url}`);
            this.setState({iotJsonDateUpdating:true});

            let response = await fetch(url);
            let responseJson = await response.json();
            let pointsArray = responseJson.points;
            if (responseJson["type"]=="posix")
            {
              
              let tagKeys = Object.keys(responseJson.points);
              console.log(`DATO DI TIPO POSIX con TAGS: ${tagKeys}`);
              pointsArray = responseJson.points[tagKeys[0]];
            }
            else {
              console.log("DATO NON DI TIPO POSIX");
            }
        
            responseJson.points = pointsArray.map((point) => 
            {
                return  point.map((p, index) =>{  
                if (index===0) return parseInt(p); 
                else
                  return parseFloat(p)
            })
             
            });
            
            //console.log(`Colonne da Json: ${responseJson.columns}`);
            //console.log(`Punti da Json: ${responseJson.points}`);

            // Data
            // http://software.es.net/pond/#/class/timeseries
     const series = new TimeSeries({
        name: responseJson.name,
        columns:responseJson.columns, // Es: ["time", "temperatura 1", "temperatura 2"]
        points: responseJson.points
    });
        
    console.log("Serie caricata:");
    console.log(`Nome:${series.name}`);
    console.log(`Columns:${series.columns}`);
    console.log(`Points -->:${series.points}`);

    console.log(series);     
    console.log(`series:${series}`);
    if (series!=null)
    {
        console.log(`->ITEM EDITOR: Data primo campione:${responseJson.points[0][0]}`);
        console.log(`->ITEM EDITOR: Primo campione della serie datato a ${moment(responseJson.points[0][0])}`);
        console.log(`->ITEM EDITOR: Primo valore della serie:${responseJson.points[0][1]}`);
        console.log(`->ITEM EDITOR: Ultimo campione della serie datato a ${moment(responseJson.points[responseJson.points.length-1][0])}`);
         const iot_item_start_time = moment(responseJson.points[0][0]);
         const iot_item_end_time = moment(responseJson.points[responseJson.points.length-1][0]);
         const iot_item_duration = moment.duration(iot_item_end_time.diff(iot_item_start_time));
         this.setState({currentDateStart: moment(iot_item_start_time,DATE_TIME_FORMAT)} , 
         () => {this.setDuration(iot_item_duration); 
                this.setState({currentDuration:iot_item_duration})});
      }
        this.setState({iotJsonDateUpdated:true, iotJsonDateUpdating:false, invalidJson:false});
          console.log("JSON VALIDO");
        } catch(error) {
          console.log("JSON NON VALIDO");
            console.error(`ITEM EDITOR: Errore nel parsing del JSON coi dati IOT: ${error}`);
            this.setState({iotJsonDateUpdated:false,  iotJsonDateUpdating:false,
            invalidJson: true // aggiunto nella nuova versione: da testare
            
          });
        }
    }

   addLineBreaks = string =>
        string.split('\n').map((text, index) => (
          <React.Fragment key={`${text}-${index}`}>
            {text}
            <br />
          </React.Fragment>
        ));
    
    render() {
      const {
        className,
        isOpen,
        toggle,
        track,
        t
      } = this.props;


      const {currentDateStart, currentDateEnd, titleValue, urlValue, descriptionValue,
        currentDuration, startOffset, endOffset, duration,
      currentStartOffset,currentEndOffset, invalidUrl, invalidTitle, invalidDuration, invalidStartDate, urlChecking ,
      invalidJson, iotJsonDateUpdated, iotJsonDateUpdating} = this.state;
    
      //alert(`Istante iniziale:${ currentDateStart.toDateString() }`);
      const butOkLabel = (this.props.item==null) ? t("tl:Aggiungi") : t("tl:Modifica");
      const titleLabel = (this.props.item==null) ? t("tl:Aggiunta") : t("tl:Modifica");
      const invalidUrlFeedback = (urlChecking ? t("tl:url_checking") : 
          (  invalidJson ? t("tl:invalidJsonUrl") : t("tl:invalid_url")));
     

      const itemType = this.props.track.type; //  (this.props.track.id==TAGS_TRACK_ID) ? "Tag" : "Risorsa";

      const itemColor = this.state.color; //(this.props.item==null) ? DefItemColors(track.type).color : this.props.item.color;
      const itemBgColor =  this.state.bgColor; //(this.props.item==null) ? DefItemColors(track.type).bgColor : this.props.item.bgColor;
      

      return(
      <div>
       
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}> {titleLabel} {itemType}</ModalHeader>
          <ModalBody>
            <Form>
            <FormGroup>
            <div><b>{t("tl:track_type")}:</b> { track!=null ? track.title : "Non pervenuto"} </div>
            </FormGroup>
            
         <FormGroup>
            <Label for="txtTitle">
                <b>{t("tl:name")}</b></Label>
                {
                  invalidTitle ? (<Input invalid id="txtTitle" style={{'width' :'100%' }} type="text" value={titleValue} onChange={this.handleTitleChange} />
                                  ) :
                               (<Input valid id="txtTitle" style={{'width' :'100%' }} type="text" value={titleValue} onChange={this.handleTitleChange} />
                               )
                }
                 <FormFeedback>{t("tl:item_editor_enter_name")}</FormFeedback>
                </FormGroup>

              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{t("tl:background_color")}</b><ColorPicker color={itemBgColor} type={itemType} onColorChanged={ this.handleBgColorChange}/>
                </div>
                <div><b>{t("tl:foreground_color")}</b><ColorPicker color={itemColor} type={itemType} onColorChanged= {this.handleColorChange}/></div>
              </div>
              <p></p>

        { itemType!=TrackType.TAG && (
          <FormGroup>
            <Label for="txtUrl">
              <b>{t("tl:url_item", {itemType})}</b> </Label>
              <IconButton 
                style = { { margin:"0px" , backgroundColor:`white`}}
                onClick={()=> this.toggleUpload()}>
                    <IconContext.Provider value={{ color: `black`, 
                                                  size: "0.8em"}}>
                       <FiUpload data-place="top" data-tip={t("tl:Upload_File")}  />
                    </IconContext.Provider>
                </IconButton>
                         
              {
                (invalidUrl || invalidJson) ? (<Input invalid id="txtUrl" type="text" value={urlValue} onChange={this.handleUrlChange} />) : 
                 
                (<Input valid id="txtUrl" type="text" value={urlValue} onChange={this.handleUrlChange} />
                 
                 )
              }
              
              <FormFeedback>{invalidUrlFeedback}</FormFeedback>
              {
                iotJsonDateUpdated && (<FormFeedback valid>{t("tl:iotJsonDatesUpdated")}</FormFeedback>)
              
              } 

              {  
                iotJsonDateUpdating && (<FormFeedback valid>{t("tl:iotJsonDatesUpdating")}</FormFeedback>)
                 
              }
             

        </FormGroup>
        )
        }

         <FormGroup>
          <Label for="txtDescription" >
            <b>{t("tl:description")}</b></Label>
            <textarea id="txtDescription" style={{'width' :'100%' }} type="text" value={descriptionValue} 
            onChange={this.handleDescriptionChange} />
          </FormGroup>

  { itemType!=TrackType.VIDEO && itemType!=TrackType.IOT && (
        <FormGroup>
        <Label for="txtDuration" style={{marginRight:"5px"}}>
           <b>{`${t("tl:Durata")}:  `}</b></Label>
        <TimeDurationInput id="txtDuration"
              value={currentDuration}
              onChange={(newValue) => this.setDuration(newValue)} />
              { invalidDuration &&   <FormText><span style={{color:"red"}}>{t("tl:invalidDuration")}</span></FormText> }
              
        </FormGroup>
    )}
      
      { (itemType==TrackType.VIDEO || itemType==TrackType.IOT)  && (
      <div id="videoId">

      <FormGroup>
        <label style={{marginRight: '0.8rem'}}>
            <b>{ itemType==TrackType.VIDEO ? t("tl:video_duration") : t("tl:iot_duration")}:</b> {moment.utc(moment.duration(duration,'milliseconds').asMilliseconds()).format(TIME_FORMAT)} 
        </label>
      </FormGroup>
         
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{itemType==TrackType.VIDEO ? t("tl:video_start") :t("tl:iot_start")}</b></div>
                <div><b>{itemType==TrackType.VIDEO ? t("tl:video_end") : t("tl:iot_end")}</b></div>
              </div>
             
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
              <div>
              <TimeDurationInput
              value={currentStartOffset}
              onChange={(newValue) => this.setStartOffset(newValue)} />
              </div>
              <div>
              <TimeDurationInput
                value={currentEndOffset}
                onChange={(newValue) => this.setEndOffset(newValue)} />
              </div>
              
            </div>
            <p></p>

            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridGap: 20 }}>
              <Range min={0} max={duration} step={1}
              value={[startOffset, endOffset]} 
              onChange={this.onChangeOffsets}
              tipFormatter={value => moment.utc(moment.duration(value,'milliseconds').asMilliseconds()).format(TIME_FORMAT)} 
              allowCross={false}
              
              />
            </div>
           
        </div>
        )}
            <p></p>
       
             
              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{t("tl:start")}</b></div>
                <div><b>{t("tl:end")}</b></div>
              </div>
             
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
              <div>
                 <FormGroup>
              <DateTime
                   onChange={this.onChangeDateStart}
                   dateFormat={DATE_FORMAT}
                   timeFormat={TIME_FORMAT}
                  value={ moment(currentDateStart).format(DATE_TIME_FORMAT) }
                  />
                  { invalidStartDate &&   <FormText><span style={{color:"red"}}>{t("tl:invalidDate")}</span></FormText> }
              
                  </FormGroup>
              </div>
              <div>
                <input readOnly type="text" value={moment(currentDateEnd).format(DATE_TIME_FORMAT)}/>
              </div>
              
            </div>
            <ReactTooltip/> 
            </Form>

          </ModalBody>
          <ModalFooter>
            <Button disabled={!this.isValidForm()} color="primary" onClick={this.createItem}>{butOkLabel}</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>{t("tl:Ignora")}</Button>
            {this.props.item!=null && <Button color="danger" onClick={this.deleteItem}>{t("tl:Elimina")}</Button>}
             
          </ModalFooter>
        </Modal>

        <div style={{display:'none'}}> 
          <ReactPlayer  
                  url={this.state.usedUrlValue}
                  onError={this.handleVideoError}
                  onDuration={this.handleVideoDuration}
                  />
            </div>

            <Modal isOpen={this.state.modalUpload} toggle={this.toggleUpload} className={className}>
            <ModalHeader toggle={this.toggleUpload}>{t("tl:Upload_File")}</ModalHeader>
            <ModalBody>
            <Label for="exampleFile">File</Label>
            <Input type="file" name="file" id="exampleFile" onChange={(event) => {
                            console.log(event.currentTarget.files[0])
                            this.setState({fileUrl :  event.currentTarget.files[0]});
                             
                            // setFieldValue("url", event.currentTarget.files[0]);
                            // setFieldValue("filename", event.currentTarget.files[0].name);
                          }} />

            
            </ModalBody>
            <ModalFooter>
              <Button disabled={!this.state.fileUrl} color="primary" onClick={() => { this.uploadFile(this.state.fileUrl)}}>{t("tl:Upload_File")}</Button>{' '}
              <Button color="secondary" onClick={this.toggleUpload}>{t("tl:Ignora")}</Button>
          </ModalFooter>
          
          </Modal>
          <UploadingModal/>
            
      </div>);
    }
  }


  export class ItemPreview extends Component  {

     constructor(props)
     {
       super(props);
       this.state = {
         isOpen : false,
         isImage: false,
         modalUpload:false
         }
      }

      async componentDidMount()
      {
        const url = this.props.item.source;
        console.log(`Item Preview: montato componente su item ${url}`);
        await this.checkImageUrl(url);
      }

      async componentDidUpdate(prevProps, prevState)
      {
        if (prevProps.item.source!==this.props.item.source)
          {
          const imageUrl = this.props.item.source;
          await this.checkImageUrl(imageUrl);
          
        }
      }

      async checkImageUrl(url)
      {
        // verifica se la url è una immagine!
          try{
            //console.log("Verifica url....");
            const res = await validator({url: url, timeout: 10000});
            //console.log("TROVATA IMMAGINE: Url:", res);
            this.setState({isImage: true});
        } 
        catch(ex) {
          //console.log("eccezione:",ex);
          //console.log("Immagine non trovata");
          this.setState({isImage: false});
        }
      }

    toggle = () => {
      //console.log(`Invocato toogle!`);
      this.setState( 
        prevState => ({isOpen : !prevState.isOpen })                   
            );
      }

      showImage = (item) =>
      { 
        console.log("sono in ShowImage");
        console.log(`Show Image di ${item.source}`);
        this.setState({zoomedImageItem:item});
      }

      onModalImageClosed = () =>
      {
        this.setState({zoomedImageItem:null});
      }

       render()
       {
         const {isImage} = this.state;
         const {item} = this.props;
         const itemLink = (item.source!=null && item.source!="") ? item.source : "#";
         return (
          <div>
            <Card  body outline color="secondary">
                <CardHeader>
                  {
                  item.type === TrackType.TAG ? (<CardTitle><b>{item.title}</b></CardTitle>) :
                  
                  (<CardLink href={itemLink} target="_blank">
                  {item.title}
                  </CardLink>)
                  }
                </CardHeader>
                {
                  isImage && (
                  
                 <CardImg  onClick={()=>this.showImage(item)}  
                           style={{ cursor: "pointer" }} top width="100%"src={item.source} alt={item.title} />
                 
                  )

                }
                <CardText>
                  {item.description}
                </CardText>
            </Card>
            { 
            this.state.zoomedImageItem!=null &&
            (<Lightbox
                      mainSrc={this.state.zoomedImageItem.source}
                      imageTitle={this.state.zoomedImageItem.title}
                      imageCaption={this.state.zoomedImageItem.description}
                      onCloseRequest={this.onModalImageClosed}
                    /> )
            }
        </div>
         );
       }
     }
  

    class ColorPicker extends React.Component {

      constructor(props)
      {
        super(props);
        console.log(`COLOR:${props.color}`);
        const rgb = this.props.color.match(/\d+/g);

        this.state = {
          displayColorPicker: false,
          color: {
            r:  rgb[0],
            g: rgb[1],
            b: rgb[2],
            a: rgb[3] == null ? 255 : rgb[3]
          },
        };

      }
     
    
      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };
    
      handleChange = (color) => {
        this.setState({ color: color.rgb })
        const newColor = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
        console.log("Nuovo colore:", newColor);
        this.props.onColorChanged(newColor);
      };

      render() {
    
        const styles = reactCSS({
          'default': {
            color: {
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
            },
            swatch: {
              padding: '5px',
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.9)',
              display: 'inline-block',
              cursor: 'pointer',
            },
            popover: {
              position: 'absolute',
              zIndex: '2',
            },
            cover: {
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
        });
    
        return (
          <div>
            <div style={ styles.swatch } onClick={ this.handleClick }>
              <div style={ styles.color } />
            </div>
            { this.state.displayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ this.handleClose }/>
              <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
            </div> : null }
    
          </div>
        )
      }
    }
     const ModalItemEditor = withTranslation()(ModalItemEditorNT); 
    //  export default ModalItemEditor;
    const mapStateToProps =  state => ({uploading : state.ui.uploading})
    export default connect( mapStateToProps, { willUploadFile })(ModalItemEditor);
