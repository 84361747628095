import update from 'immutability-helper';
import { useSelector, useDispatch } from "react-redux";
import * as ContainerSelectors from '../selectors/container'
import * as ContainerTypes from '../types/container';
import { select } from 'redux-saga/effects';
import { TiArrowSortedDown } from 'react-icons/ti';
var moment = require('moment');
var _ = require('lodash');

const initialState: ContainerTypes.ContainerInitialState = {
    containers: [],
    experiments: [],
    public_container: [],
    public_container_links: [],
    sync_sessions: []
}
export const containerReducer = (state = initialState, action: ContainerTypes.ContainerActionTypes) => {
    // console.log("in container reducer: ", action)
    switch (action.type) {
        case ContainerTypes.ContainerTypes.DID_GET_CONTAINERS:
            return update(state, { containers: { $set: action.containers } });
        case ContainerTypes.ContainerTypes.DID_GET_SYNC_SESSIONS:
            return update(state, { sync_sessions: { $set: action.sessions } })
        case ContainerTypes.ContainerTypes.DID_GET_EXPERIMENTS:
            // console.log("in container reducer111111: ", action)
            const experiments = action.experiments.response.data.listExperiments.experiments.map((value: any, i: number) => {
                value.tracks = JSON.parse(value.tracks)
                if (value.items.length !=0) {
                    value.items = JSON.parse(value.items)
                    value.items = value.items.map((value: any, i: number) => {
                        value.start_time = moment(value.start_time, "YYYY-MM-DD HH:mm:ss.SSS Z");
                        value.end_time = moment(value.end_time, "YYYY-MM-DD HH:mm:ss.SSS Z");
                        return value
                    })
                }
                return value
            })
            console.log("exp", experiments)
            let linkList = []
            let sortedList = []
            if (action.experiments.responsePublic.data.listPublishedLinks.items) {
                linkList = action.experiments.responsePublic.data.listPublishedLinks.items
                console.log('list of links ', linkList)
                sortedList = _.orderBy(linkList, "pcreated", "desc");
                console.log('list of links sorted', sortedList)
            }
            return update(state, { experiments: { $set: experiments }, public_container_links: { $set: sortedList } });
        case ContainerTypes.ContainerTypes.DID_GET_PUBLIC_CONTAINER:
            let container = action.containers
            container.map((item: any) => {
                console.log("item: ", item)
                item.experimentsjson = JSON.parse(item.experimentsjson)
                item.experimentsjson.map((value: any, i: number) => {
                    value.tracks = JSON.parse(value.tracks)
                    value.items = JSON.parse(value.items)
                    value.items = value.items.map((value: any, i: number) => {
                        value.start_time = moment(value.start_time, "YYYY-MM-DD HH:mm:ss.SSS Z");
                        value.end_time = moment(value.end_time, "YYYY-MM-DD HH:mm:ss.SSS Z");
                        return value
                    })
                    return value
                })
            })
            return update(state, { public_container: { $set: container } });
        case ContainerTypes.ContainerTypes.DID_ADD_ELEMENT:
            let experiment = action.experiments
            const index = experiment[action.experimentIndex].items.findIndex((element: any) => {
                return element.id == action.element.id
            })
            switch (action.event) {
                case "Created":
                    console.log("intercettato CREATED", action.element, action.experimentIndex)
                    experiment[action.experimentIndex].items.push(action.element)
                    return update(state, { experiments: { $set: experiment } });
                case "Deleted":
                    console.log("intercettato DELETED", action.element, action.experimentIndex);
                    experiment[action.experimentIndex].items.splice(index, 1)
                    return update(state, { experiments: { $set: experiment } });
                case "Changed":
                    console.log("intercettato CHANGED", action.element, action.experimentIndex);
                    experiment[action.experimentIndex].items[index] = action.element
                    return update(state, { experiments: { $set: experiment } });
                case "TITLE_DESCR":
                    console.log("intercettato TITLE", action.element, action.experimentIndex);
                    experiment[action.experimentIndex].title = action.element[0]
                    experiment[action.experimentIndex].description = action.element[1]
                    return update(state, { experiments: { $set: experiment } });
            }
        default:
            return state;
    }
}