import * as ProfileTypes from '../types/profile';
import { string } from 'yup';

export type WillUpdateProfile = (
    profile: any
  ) => ProfileTypes.IWillUpdateProfile;

  export const willUpdateProfile: WillUpdateProfile = (
    profile: any
  ) => ({
    profile,
    type: ProfileTypes.ProfileTypes.WILL_UPDATE_PROFILE
  });
  