export const getSignupUsername = (state: any) => {
    return state.auth.authData;
  };
  
  export const getAuthStateErrorData = (state: any) => {
    return state.auth.errData;
  };
  
  export const getLoginData = (state: any) => {
    return state.auth.login;
  };

  export const getLoggedData = (state: any) => {
    return state.auth.logged;
  };
  
  export const getSignUpData = (state: any) =>{
    return state.router.location.pathname;
  };

  export const isAuthChecked = (state: any) => state.auth.checked 
