import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer } from './auth'
import { profileReducer } from './profile'
import { containerReducer} from './container'
import { uiReducer} from './ui'
export interface RootState {
  router: any;
  auth: any;
  profile: any;
  containers: any;
  ui: any
}

export const createRootReducer = (history: any) => {
  return combineReducers<RootState | undefined>({
    router: connectRouter(history),
    auth: authReducer,
    profile: profileReducer,
    containers: containerReducer,
    ui: uiReducer
  });
}
