import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom';
import { store } from './store';



// Create main element
export const render = (Component: () => JSX.Element) => {

  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('root')
  )
}

